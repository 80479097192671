import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  TFormField,
  TJourneyConfig,
  TFieldChangeHandler,
  TFormData,
  IStringIndex,
} from "../../types";
import { useLayoutEffect, useState } from "react";
import { EmbeddedJourneySection } from "../EmbeddedJourneySection";
import { getFieldOptions, replacePlaceholders } from "../../formHandling";
import { get } from "lodash";
import { createNumberofClaimsText } from "../../formHandling";
import { applyRule } from '../../utils/rulesEngine';

export const SummaryField = (props: {
  field: TFormField;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
}) => {
  const { field, formData, config, changeHandler } = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // The section that can get edited from this summary field
  const { summaryParams } = field;
  const editSection = config.sections.find(
    (section) => section.name === summaryParams?.sectionName
  );
  const activeIndex = config.sections.findIndex((section)=> section.name === summaryParams?.sectionName)

  // Ugly mess courtesy of ERS.
  // One day, when time allows, I will rewrite this.
  const parents = summaryParams?.parent;
  const renderSummaryArray = (parent?: string | string[]) => {
    const multipleParents = Array.isArray(parent);
    const combinedItems: any = [];
    if (multipleParents) {
      parent?.forEach((p) => {
        const items = get(formData.values, p);
        combinedItems.push(items);
      });
    }

    const items = multipleParents
      ? combineParentArrayItems(combinedItems)
      : get(formData.values, parent!);
    if (!items) {
      return null;
    }

    const elements = [];
    if (Array.isArray(items)) {
      if (items.length > 1) {
        elements.push(<Divider key="divider" sx={{ paddingBottom: 2 }} />);
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        elements.push(
          <div key={`item-${i}`}>
            <SummaryItem
              itemIndex={i}
              globalFormData={props.formData}
              itemKey={`item-${i}`}
              formData={{
                values: item,
                validations: formData.validations,
              }}
              field={field}
              getFormattedValue={getFormattedValue}
            />
            {i < items.length - 1 && <Divider sx={{ paddingBottom: 2 }} />}
          </div>
        );
      }
    }
    return elements;
  };

  const getFormattedValue = (fieldValue: string, values = formData.values) => {
    const matched = fieldValue.match(/\$\{[^}]+}/g);
    if (!matched) {
      return fieldValue;
    }
    let resultString = fieldValue;

    matches: for (let m of matched) {
      const fieldName = m.substring(2, m.length - 1);
      const matchingValue = fieldName.includes('||') ? replacePlaceholders(values,m): values[fieldName];
      let matchingField = null;
      for (let s of config.sections) {
        for (let f of s.fields) {
          if (f.fields) {
            for (let xyzzy of f.fields) {
              if (xyzzy.name === fieldName) {
                matchingField = xyzzy;
                break;
              }
            }
          } else {
            if (f.name === fieldName) {
              matchingField = f;
              break;
            }
          }
        }
      }
      // This will have to do for now
      if (matchingField && typeof matchingValue === "boolean") {
        resultString = resultString.replace(m, matchingValue === true ? "Yes" : "No");
        continue matches;
      }

      if (matchingField && typeof matchingValue === "object" && Array.isArray(matchingValue)) {
        resultString = resultString.replace(m, matchingValue.length + "");
        continue matches;
      }

      // Is this backwards compatible with all journeys?
      if (matchingField && (matchingField.options || matchingField.dataSource)) {
        const matchingOptions = getFieldOptions(matchingField, formData);
        for (let o of matchingOptions) {
          if (o.value === matchingValue) {
            resultString = resultString.replace(m, o.label);
            continue matches;
          }
        }
      }

      if (
        matchingValue !== undefined &&
        matchingField &&
        (matchingField.variant === "money" || matchingField.type === "currency")
      ) {
        if (isNaN(Number(matchingValue))) {
          resultString = resultString.replace(m, matchingValue);
        } else {
          resultString = resultString.replace(
            m,
            "£" + Number(matchingValue).toLocaleString("en-GB")
          );
        }
        continue matches;
      }

      if (matchingValue !== undefined) {
        resultString = resultString.replace(m, matchingValue);
      } else {
        const v = get(values, fieldName) ?? m;
        resultString = resultString.replace(m, v);
      }
    }
    return resultString.match(/\$\{[^}]+}/g) ? "N/A" : resultString;
  };

  const renderSummaryItems = (formData: any, key: any = Math.random()) => {
    const options = createNumberofClaimsText(
      formData?.values?.period || "12",
      getFieldOptions(field, formData)
    );
    return options?.map((option: any, idx: number) => {
      return option.disabled ? null : (
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="start"
          className="SummaryField"
          sx={{ margin: "5px 0 5px 0" }}
          key={key + "option" + idx + "-" + option.label + option.value}
        >
          <p style={{ flex: 1 }}>
            {replacePlaceholders(formData.values, option.label, true, idx + 1)}
          </p>
          {option?.value && (
            <b style={{ flex: 1, textAlign: "end" }}>
              {getFormattedValue(option.value, formData.values)}
            </b>
          )}
        </Stack>
      );
    });
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  const renderEditDialog = () => {
    if (!editSection) {
      return null;
    }

    const handleSuccess = () => {
      closeEditDialog();
    };

    return (
      <Dialog
        open={editDialogOpen}
        scroll="paper"
        fullWidth
        PaperProps={{ sx: { width: "100%", margin: "0px" } }}
      >
        <DialogContent className="dialogContent">
          <EmbeddedJourneySection
          activeIndex={activeIndex}
            section={{ ...editSection, heading: "Adjust your cover" }}
            config={config}
            onCancel={closeEditDialog}
            onSuccess={handleSuccess}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box
      className="summaryField"
      style={{
        backgroundColor: "white",
        padding: 12,
        borderRadius: 16,
        marginTop: 16,
        border: "1px solid rgba(0,0,0,0.25)",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="SummaryField"
      >
        <Typography variant="h4" className="summaryFieldTitle">
          {field.title}
        </Typography>
        {editSection && (
          <IconButton
            color="primary"
            component="span"
            onClick={openEditDialog}
            disabled={field.disabled}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      {parents ? (
        <Stack spacing={2}>{renderSummaryArray(parents)}</Stack>
      ) : (
        renderSummaryItems(formData)
      )}
      {renderEditDialog()}
    </Box>
  );
};

/**
 * Combines multiple parent items represented as an array of arrays, where each inner array
 * represents objects with key-value pairs. The function merges key-value pairs with the same
 * index in each inner array, effectively combining the parent items into a single array of objects.
 *
 * @param {any[]} items - An array of arrays containing parent items to combine.
 * @returns {any[]} An array of combined parent items.
 */
function combineParentArrayItems(items: any): any[] {
  if (!items || !items.length) {
    return [];
  }

  try {
    return items[0]?.map((_: any, index: number) =>
      items.reduce(
        (combinedObject: any, array: any[]) => Object.assign(combinedObject, array[index]),
        {}
      )
    );
  } catch (error) {
    return [];;
  }
}

const SummaryItem = ({
  field,
  getFormattedValue,
  formData,
  globalFormData,
  itemKey = Math.random(),
  itemIndex,
}: {
  field: TFormField;
  formData: TFormData;
  globalFormData: TFormData;
  itemKey: any;
  itemIndex: number;
  getFormattedValue: (fieldValue: string, values?: IStringIndex<any>) => string;
}) => {
  const [localOptions, setLocalOptions] = useState<any>(
    createNumberofClaimsText(formData?.values?.period || '12', getFieldOptions(field, formData))
  );

  useLayoutEffect(() => {
    (async () => {
      const updatedOptions = await Promise.all(
        localOptions?.map(async (option: any) => {
          if (option?.rules) {
            const res = await applyRule(option.rules, globalFormData.values);
            if (res && res.conditionMet) {
              option.disabled = false;
            } else {
              option.disabled = true;
            }
          }
          return option;
        })
      );

      setLocalOptions(updatedOptions);
    })();
  }, [globalFormData.values]);

  return (
    <>
      {localOptions?.map((option: any, idx: number) => {
        return option.disabled ? null : (
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="start"
            className="SummaryField"
            sx={{ margin: '5px 0 5px 0' }}
            key={itemKey + 'option' + idx + '-' + option.label + option.value}
          >
            <p style={{ flex: 1 }}>
              {replacePlaceholders(formData.values, option.label, true, itemIndex + 1)}
            </p>
            {option?.value && (
              <b style={{ flex: 1, textAlign: 'end' }}>
                {getFormattedValue(option.value, formData.values)}
              </b>
            )}
          </Stack>
        );
      })}
    </>
  );
};
