import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../../ProductsApiContext";
import { createPaymentIntent, getProduct } from "../../../apiCalls";
import { IStringIndex, TFormData, TJourneyConfig } from "../../../types";
import { getEnvParams } from "../../../utils";
import CheckoutForm from "./CheckoutForm";

export interface PaymentSectionProps {
  formData: TFormData;
  config: TJourneyConfig;
  submitQuoteUpdate: ((values: IStringIndex<any>) => void) | undefined;
  paymentComponentRef: any;
  setBusy?: Dispatch<SetStateAction<boolean>>;
}
const PaymentSection = ({
  formData,
  config,
  submitQuoteUpdate,
  paymentComponentRef,
  setBusy,
}: PaymentSectionProps) => {
  const { product } = useContext(ProductsContext);
  let { uuid, mode } = getEnvParams();

  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState(true);
  const [values, setValues] = useState(formData.values);
  const [error, setError] = useState<{ type: any; message?: string } | null>(null);
  const [stripePromise, setstripePromise] = useState<Promise<Stripe | null>>();
  const [paymentProccessed, setPaymentProccessed] = useState(false);
  const [amount, setAmount] = useState<number | undefined>(
    formData.values?.paymentFrequency === "MONTHLY"
      ? formData.values.quote?.deposit
      : formData.values.quote?.premium
  );

  const quote = formData?.values?.quote;

  const brokerId = uuid ?? values?.quote?.mainBrokerId;

  useEffect(() => {
    if (product && brokerId) {
      const stripeP = loadStripe(product?.brokers[0][brokerId]?.stripePK);
      setstripePromise(stripeP);
    } else if (mode === "TEST" && window.location.host.startsWith("localhost:")) {
      getProduct(brokerId, config?.product?.id || "").then((res) => {
        const { stripePK } = res?.brokers[0][brokerId];
        const stripeP = loadStripe(stripePK);
        setstripePromise(stripeP);
      });
    }
  }, [product, brokerId, mode, config?.product?.id]);

  useEffect(() => {
    if (quote?.premium && quote?.quoteId) {
      (async () => {
        try {
          const response = await createPaymentIntent(quote?.quoteId, values.MTA);
          const { client_secret, status, amount } = response?.data;
          setAmount(Number(amount) / 100);
          if (status === "succeeded") {
            setError({
              type: "success",
              message: response?.message,
            });
            setPaymentProccessed(true);
            setClientSecret(client_secret);
          } else {
            setPaymentProccessed(false);
            setClientSecret(client_secret);
          }
          setIsProcessing(false);
        } catch (error: any) {
          setError({
            type: "error",
            message: "Something went wrong while loading payment service",
          });
          setIsProcessing(false);
        }
      })();
    } else {
      setError({ type: "error", message: "Missing required data to load payment service." });
      setIsProcessing(false);
    }
  }, [quote?.premium, quote?.quoteId]);

  return (
    <Container>
      {quote?.state === "REFERRED" && <ReferralPopup quote={quote} />}
      {isProcessing && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {stripePromise && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
          }}
        >
          <CheckoutForm
            amount={amount}
            paymentProccessed={paymentProccessed}
            setBusy={setBusy}
            ref={paymentComponentRef}
            submitQuoteUpdate={submitQuoteUpdate}
            quote={quote}
            values={values}
            config={config}
          />
        </Elements>
      )}
      {error?.type === "success" && (
        <>
          <Alert
            severity={error?.type ?? "error"}
            sx={{ marginTop: 2, border: "1px solid rgba(0,0,0,0.5)" }}
          >
            {error?.message ??
              "Something went wrong while loading payment service. Please try again later."}
          </Alert>
        </>
      )}
      {(!isProcessing && (!stripePromise || !clientSecret)) ||
        (error && error?.type !== "success" && (
          <Alert
            severity={error?.type ?? "error"}
            sx={{ marginTop: 2, border: "1px solid rgba(0,0,0,0.5)" }}
          >
            {error?.message ??
              "Something went wrong while loading payment service. Please try again later."}
          </Alert>
        ))}
    </Container>
  );
};

export default PaymentSection;

const ReferralPopup = ({ quote }: { quote: any }) => {
  let { uuid } = getEnvParams();
  return (
    <Dialog
      open={true}
      scroll="paper"
      fullWidth
      PaperProps={{ sx: { width: "100%", margin: "0px" } }}
    >
      <DialogContent
        className="dialogContent"
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Alert severity="info" sx={{ border: "1px solid rgba(0,0,0,0.5)", width: "100%" }}>
          <AlertTitle>Application Referred</AlertTitle>
          <Stack spacing={2}>
            <p>
              Thank you for completing the application. Your responses have been referred to a
              member of our team who will review and be in contact shortly.
            </p>
            <p>
              Your application number is: <b>{quote?.policyNumber}</b>
            </p>
            {quote?.referalReasons && (
              <>
                <b>Referral Reasons:</b>
                <ul>
                  {quote?.referalReasons?.map((item: any) => (
                    <li style={{ marginLeft: 20 }}>{item?.message}</li>
                  ))}
                </ul>
              </>
            )}
          </Stack>
        </Alert>
        <div>
          <Button
            onClick={() => window.location.replace(`${window.location.origin}/?uuid=${uuid}`)}
            fullWidth
            variant="text"
            color="error"
          >
            Exit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
