export const PreviewJSON = (props: { obj: any }) => {
  return (
    <div>
      <pre
        style={{
          fontFamily: "monospace",
          backgroundColor: "#E1E1E1",
          color: "#1E1E1E",
          padding: 8,
          overflow: "scroll",
          margin: 0,
          minHeight: 100,
          maxHeight: 300,
          border: "1px solid rgba(0,0,0,0.5)",
        }}
      >
        {JSON.stringify(props.obj, null, 2)}
      </pre>
    </div>
  );
};
