// =================================================================================================
// This is the dynamic form that gets rendered from a config
// =================================================================================================

import {
  Alert,
  AlertTitle,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
// import { buildPolicyPayload, updateHistoricIds } from "../ERS/payload";
import { JourneyContext } from "../JourneyContext";
import { createQuote, patchQuote, updateQuoteStage } from "../apiCalls";
import { validateField } from "../fieldValidation";
import {
  checkDependents,
  checkOptionDependents,
  checkRules,
  getFormData,
  initDependencies,
  replacePlaceholders,
} from "../formHandling";
import { dispatchTrackingEvent } from "../modules/googleAnalytics";
import {
  IStringIndex,
  TAlert,
  TBranding,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneyMode,
  TJourneySection,
  TQuote,
} from "../types";
import { Branding } from "./Branding";
import Confetti from "./Confetti";
import { DebugView } from "./DebugView";
import { JourneyHeader } from "./JourneyHeader";
import { JourneySection } from "./JourneySection";
import { MTASuccessPage } from "./MTASuccessPage";
import { RegulatoryFooter } from "./RegulatoryFooter";
import { SubmitButton } from "./SubmitButton";
import { applyRule } from "../utils/rulesEngine";
import { PaymentContext } from "../PaymentContext";
import { getEnvParams } from "../utils";

// =================================================================================================
// Main component
// =================================================================================================

export const Journey = (props: {
  mode: TJourneyMode;
  config: TJourneyConfig;
  branding?: TBranding;
  setSuccessToken: React.Dispatch<React.SetStateAction<string>>;
  setActiveJourney?: (c: TJourneyConfig | null) => void;
}) => {
  // -----------------------------------------------------------------------------------------------
  // State and variables
  // -----------------------------------------------------------------------------------------------

  const [activeIndex, setActiveIndex] = useState(-1);
  const [busy, setBusy] = useState(false);
  const [formData, setFormData] = useState<TFormData>({
    values: {},
    validations: {},
  });

  const [showMTASuccess, setShowMTASuccess] = useState(false);
  const [NFResponse, setNFResponse] = useState<any>(undefined);

  const [submitAlert, setSubmitAlert] = useState<TAlert | undefined>();

  const { config, mode, branding } = props;
  const sections = config.sections;
  const section: TJourneySection | undefined =
    activeIndex >= 0 ? sections[activeIndex] : undefined;
  const finalSection = sections.find((section) => section.isFinal);

  const { paymentConfirmed, paymentDetails } = useContext(PaymentContext);
  const paymentComponentRef = useRef<any>();

  const { isEmbedded } = getEnvParams();

  // -----------------------------------------------------------------------------------------------
  // useEffects
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 20);
  }, [section]);

  useEffect(() => {
    console.log("Journey config has changed");
    if (!config?.sections) {
      return;
    }

    preprocessInitialValues(config.initialValues ?? {});

    let allFields: TFormField[] = [];
    for (let s of config.sections) {
      allFields = [...allFields, ...s.fields];
    }

    const getInitialValidations = (initialValues: IStringIndex<any>) => {
      const validations: IStringIndex<boolean> = {};
      for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
        const matchingField = allFields.find(
          (field) => field.name === fieldName
        );
        if (matchingField) {
          if (matchingField.autoClear) {
            validations[fieldName] = false;
          } else {
            validations[fieldName] = validateField(
              matchingField,
              fieldValue,
              initialValues
            );
          }
        }
      }
      return validations;
    };

    const initialValidations = config.initialValues
      ? getInitialValidations(config.initialValues)
      : {};
    const newIndex = 0;
    setActiveIndex(newIndex);
    initDependencies(allFields, mode);
    const newFormData = getFormData(
      allFields,
      {
        values: config.initialValues || {},
        validations: initialValidations,
      },
      mode,
      config
    );
    setFormData(newFormData);
    setSubmitAlert(undefined);
  }, [config, sections, mode]);

  // -----------------------------------------------------------------------------------------------
  // Field change handler for updating values and validations in state
  // -----------------------------------------------------------------------------------------------

  const changeHandler: TFieldChangeHandler = async (
    field: TFormField,
    newValue: any
  ) => {
    let allFields: TFormField[] = [];
    for (let s of config.sections) {
      allFields = [...allFields, ...s.fields];
    }

    //console.log("Current values:", JSON.stringify(formData.values, null, 2));
    // console.log(`Field "${field.name}" has new value ${JSON.stringify(newValue)}`);
    let newValidations = { ...formData.validations };
    let newValues = { ...formData.values, [field.name]: newValue };

    const validation = validateField(field, newValue, newValues);
    newValidations[field.name] = validation;
    checkDependents(field, allFields, newValues, newValidations, config);
    checkOptionDependents(field, allFields, newValues, newValidations);
    // NEW: uses json-rules-engine structure
    await checkRules(field, allFields, newValues, newValidations, config);
    const newFormData: TFormData = {
      values: { ...formData.values, ...newValues },
      validations: { ...formData.validations, ...newValidations },
    };
    // console.log("New values:", JSON.stringify(newFormData.values, null, 2));
    setFormData(newFormData);
  };

  // -----------------------------------------------------------------------------------------------
  // Main submit handler
  // -----------------------------------------------------------------------------------------------

  const handleSubmit = async () => {
    // Clear submit error if one exists.
    if (submitAlert) {
      setSubmitAlert(undefined);
    }

    // Clean up form data. Right now this just means removing string whitespace.
    const cleanValues: IStringIndex<any> = {};
    for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
      if (typeof fieldValue === "string") {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, " ").trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }

    // Track user input via GTM
    try {
      dispatchTrackingEvent(config, formData, activeIndex, props.branding);
    } catch (error:any) {
      console.error(`Google Dispatcher failed: ${error?.message}`);
    }

    // bad but will do for now
    if (
      section?.isPayment &&
      cleanValues?.MTA &&
      cleanValues?.MTA.stripeCollects &&
      !cleanValues.MTA.stripeMTAConfirmed &&
      !paymentConfirmed &&
      Number(cleanValues?.MTA.mtaAdjustment) + Number(cleanValues?.MTA.mtaFee) >
        0
    ) {
      const results = await paymentComponentRef.current.triggerPayment(
        cleanValues
      );
      if (results && results?.error) {
        setSubmitAlert({
          body: results.error.message,
          severity: "error",
        });
        return;
      } else {
        cleanValues["paymentIntent"] = results?.paymentIntent;
      }
    } else if (section?.isPayment && !cleanValues?.MTA) {
      const results = await paymentComponentRef.current.triggerPayment(
        cleanValues
      );
      if (results && results?.error) {
        setSubmitAlert({
          body: results.error.message,
          severity: "error",
        });
        return;
      } else {
        cleanValues["paymentIntent"] = results?.paymentIntent;
      }
    } else if (paymentConfirmed && paymentDetails) {
      cleanValues["paymentIntent"] = paymentDetails;
    }

    // Custom ERS behaviour - Candidate for deletion, but leaving because I will need snippets from this

    // if (config.product.id === "pro-75791180-70b9-4ec7-8bb5-d20a69787d69") {
    //   updateHistoricIds(cleanValues); // modifies object in place
    //   const testPolicy = buildPolicyPayload(cleanValues);
    //   cleanValues.payloadERS = testPolicy;
    //   const nextSection = config.sections[activeIndex + 1];
    //   // We only POST a new quote if the next section is the quote section
    //   // i.e. the minimum required form data has been captured.
    //   if (nextSection?.isQuote && config.APIs?.new) {
    //     setBusy(true);
    //     postQuote(config, config.APIs.new, cleanValues)
    //       .then((result) => handleNewQuote(result))
    //       .finally(() => setBusy(false));
    //   } else {
    //     // No API calls, just try to advance to next section.
    //     prepNextSection(cleanValues, nextSection);
    //   }
    //   return;
    // }

    // What we do with the form data depends on the quote's existence and state.
    const { quote } = formData.values;
    // NEW: if we're in step 1, or we don't have a quote we call the new quotes api
    const step1 = activeIndex === 0;
    if (!quote && step1) {
      // Quote does not exist yet, we might create one.
      // if step 1 and there is already a quote then skip to step 2.
      // if (quote && quote.quoteId) prepNextSection(cleanValues, nextSection);
      submitNewQuote(cleanValues);
    } else {
      switch (quote.state) {
        case "QUOTE":
          // Update an existing quote with either PATCH or BOUND.
          submitQuoteUpdate(cleanValues);
          break;
        case "BOUND":
        case "REFERRED":
          if (quote.state === "REFERRED" && quote.type === "QTO") {
            submitQuoteUpdate(cleanValues);
          } else {
            // This will always be an MTA.
            submitQuoteMTA(cleanValues);
          }
          break;
      }
    }
  };

  const { quote } = formData.values;
  // If next section exists, prepare form data and proceed to it.
  const prepNextSection = (
    values: IStringIndex<any>,
    nextSection: TJourneySection | undefined
  ) => {
    const dynamicSection = sections[activeIndex + 1];
    let newActiveIndex = activeIndex;
    if (dynamicSection?.name === "monthlyPaymentConfirmation") {
      if (values?.paymentFrequency.toUpperCase() !== "MONTHLY") {
        newActiveIndex = activeIndex + 1;
      }
    }
    if (nextSection) {
      const nextData: TFormData = getFormData(
        nextSection.fields,
        {
          ...formData,
          values: values,
        },
        mode,
        config
      );
      setFormData(nextData);
      setActiveIndex(newActiveIndex + 1);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit a new quote
  // -----------------------------------------------------------------------------------------------

  const submitNewQuote = (values: IStringIndex<any>) => {
    // NEW: If journey is in step-1 it calls /create to create a new quote with no premium
    const step1 = activeIndex === 0;
    const nextSection = config.sections[activeIndex + 1];
    if (step1 && config?.APIs?.new) {
      setBusy(true);
      const apiConfig = config?.APIs?.new;
      if (nextSection?.isQuote) {
        apiConfig.payload.quote.isQuote = true;
      }
      createQuote(config, config.APIs.new, values, props.branding)
        .then((results) => {
          handleNewQuote(results.data);
        })
        .catch((error: any) => {
          console.log("ERROR: ", error.message);
          setSubmitAlert({
            body: "There was a problem creating a quote, please wait a moment and try again.",
            severity: "error",
          });
          return;
        })
        .finally(() => setBusy(false));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote update (patch or bound)
  // -----------------------------------------------------------------------------------------------

  const submitQuoteUpdate = async (values: IStringIndex<any>) => {
    // If we are on the final section, this will initiate BOUND.
    // Otherwise, it's just a regular old PATCH.
    if (section === finalSection && !section?.isPayment) {
      submitQuoteBound(values);
    } else if (
      section?.isPayment &&
      values.paymentFrequency.toUpperCase() !== "MONTHLY"
    ) {
      submitQuoteBound(values);
    } else {
      submitQuotePatch(values);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote bound
  // -----------------------------------------------------------------------------------------------

  const submitQuoteBound = (values: IStringIndex<any>) => {
    const nextSection = config.sections[activeIndex + 1];
    if (config.APIs?.bound) {
      setBusy(true);
      patchQuote(config, config.APIs.bound, values)
        .then((result) => handleQuoteBound(result))
        .catch((err) => console.log(err));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote patch
  // -----------------------------------------------------------------------------------------------

  const submitQuotePatch = (values: IStringIndex<any>) => {
    const nextSection = config.sections[activeIndex + 1];
    if (config.APIs?.patch) {
      const apiConfig = config.APIs.patch;
      if (nextSection.isQuote || Number(values.quote.premium) > 0) {
        apiConfig.payload.quote.isQuote = true;
      }
      setBusy(true);
      patchQuote(config, config.APIs.patch, values)
        .then((result) => {
          const pipelineStage = getPipelineStage(activeIndex, sections);
          updateQuoteStage(values.quote.quoteId, pipelineStage)
            .then((res) => console.log(res))
            .catch((err) => console.error(err.message));
          handleQuotePatch(result);
        })
        .finally(() => setBusy(false));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Submit quote MTA
  // -----------------------------------------------------------------------------------------------

  const submitQuoteMTA = (values: IStringIndex<any>) => {
    const dynamicSection = sections[activeIndex + 1];
    let newActiveIndex = activeIndex;
    if (dynamicSection?.name === "monthlyPaymentConfirmation") {
      if (values?.paymentFrequency.toUpperCase() !== "MONTHLY") {
        newActiveIndex = activeIndex + 1;
      }
    }
    const nextSection = config.sections[newActiveIndex + 1];
    const goCardlessPolicy =
      values.quote.paymentFrequency.toUpperCase() !== "MONTHLY" &&
      values.quote.paymentProvider === "GoCardless";
    const useOldQP = goCardlessPolicy ? false : !values?.quote?.useNewMtaQp;
    // Get MTA preview if next section is quote screen.
    // Only perform the actual MTA on the final section.
    if (nextSection?.isQuote && config.APIs?.mta) {
      console.log("mta_preview");
      setBusy(true);
      const apiCopy = JSON.parse(JSON.stringify(config.APIs?.mta));
      apiCopy.payload.quote.journey = "mta_preview";
      patchQuote(config, apiCopy, values)
        .then((result) => handleQuotePreviewMTA(result))
        .finally(() => setBusy(false));
    } else if (nextSection?.isPayment && useOldQP && config.APIs?.mta) {
      console.log("MTA::Old quote processor");
      setBusy(true);
      patchQuote(config, config.APIs.mta, values)
        .then((result) => handleQuoteMTA(result))
        .finally(() => setBusy(false));
    } else if (
      (section === finalSection || section?.isPayment) &&
      config.APIs?.mta
    ) {
      console.log("MTA::New quote processor");
      setBusy(true);
      patchQuote(config, config.APIs.mta, values)
        .then((result) => handleQuoteMTA(result))
        .finally(() => setBusy(false));
    } else {
      // No API calls, just try to advance to next section.
      prepNextSection(values, nextSection);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of posting new quote
  // -----------------------------------------------------------------------------------------------

  const handleNewQuote = (result: any) => {
    if (result === null || result?.errorMessage || !Array.isArray(result)) {
      setSubmitAlert({
        body: "There was a problem getting your quote, please wait a moment and try again.",
        severity: "error",
      });
      return;
    }
    let quote = null;
    if (result.length === 1) {
      quote = result[0];
    } else {
      // Do something here? What exactly
      return;
    }
    if (quote) {
      const newIndex = activeIndex + 1;
      const nextSection = sections[newIndex];
      const nextData: TFormData = getFormData(
        nextSection.fields,
        formData,
        mode,
        config
      );
      setFormData({
        validations: { ...nextData.validations },
        values: { ...nextData.values, quote },
      });
      setActiveIndex(newIndex);
    } else {
      // Do something here? What exactly
    }
  };
  
  // -----------------------------------------------------------------------------------------------
  // Handle result of patching a quote
  // -----------------------------------------------------------------------------------------------

  const handleQuotePatch = (result: any) => {
    if (
      result === null ||
      result?.errorMessage ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      setSubmitAlert({
        body: "There was a problem updating your quote, please wait a moment and try again.",
        severity: "error",
      });
      return;
    }

    const quote = result[0];
    if (quote.state === "DECLINED") {
      setSubmitAlert({
        body: `Declined: ${quote.rejectionReasons[0].message} - ${quote.rejectionReasons[0].value}`,
        severity: "error",
      });
      return;
    }
    const dynamicSection = sections[activeIndex + 1];
    let newActiveIndex = activeIndex;
    if (dynamicSection?.name === "monthlyPaymentConfirmation") {
      if (formData?.values?.paymentFrequency.toUpperCase() !== "MONTHLY") {
        newActiveIndex = activeIndex + 1;
      }
    }
    const newIndex = newActiveIndex + 1;
    const nextSection = sections[newIndex];
    const nextData: TFormData = getFormData(
      nextSection.fields,
      formData,
      mode,
      config
    );
    setFormData({
      validations: { ...nextData.validations },
      values: { ...nextData.values, quote },
    });
    setActiveIndex(newIndex);
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of binding a quote
  // -----------------------------------------------------------------------------------------------

  const handleQuoteBound = (result: any) => {
    if (
      result === null ||
      result?.pcError ||
      result?.errorMessage ||
      result?.error ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      setBusy(false);
      setSubmitAlert({
        body:
          result?.error ||
          result?.pcError ||
          "There was a problem processing your quote, please wait a moment and try again.",
        severity: "error",
      });
      return;
    }
    const r = Array.isArray(result) ? result[0] : result;
    if (config?.payment?.provider === "stripe") {
      //window.location.replace(window.location.origin + "/?user_session_token=" + r.quoteId);
      props.setSuccessToken(r.quoteId);
    } else if (r.redirectUrl) {
      window.location.replace(r.redirectUrl);
    } else if (r.policyNumber) {
      setNFResponse(r);
      setBusy(false);
    } else {
      alert("No redirect url?");
      setBusy(false);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of performing MTA
  // -----------------------------------------------------------------------------------------------

  const handleQuoteMTA = (result: any) => {
    if (
      result === null ||
      result?.errorMessage ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      setSubmitAlert({
        body: "There was a problem performing the MTA, please wait a moment and try again.",
        severity: "error",
      });
      return;
    }
    setFormData({
      validations: { ...formData.validations },
      values: { ...formData.values, MTA: result[0] },
    });
    setShowMTASuccess(true);
  };

  // -----------------------------------------------------------------------------------------------
  // Handle result of performing MTA preview
  // -----------------------------------------------------------------------------------------------

  const handleQuotePreviewMTA = (result: any) => {
    if (
      result === null ||
      result?.errorMessage ||
      !Array.isArray(result) ||
      result.length !== 1
    ) {
      const errorMessage = result.errorMessage?.includes(
        `AgreementHasUngeneratedBatches`
      )
        ? `Unable to perform MTA at this time due to current Agreement has ungenerated batches. Please try again later.`
        : `There was a problem previewing the MTA, please wait a moment and try again.`;
      setSubmitAlert({
        body: errorMessage,
        severity: "error",
      });
      return;
    }
    prepNextSection(
      { ...formData.values, MTA: result[0] },
      sections[activeIndex + 1]
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Navigation button handlers
  // -----------------------------------------------------------------------------------------------

  const getBackHandler = () => {
    if (mode === "MTA" || mode === "RESUME") {
      if (activeIndex > 0) {
        return handleBack;
      } else {
        return undefined;
      }
    }
    return handleBack;
  };

  const getForwardHandler = () => {
    if (mode === "TEST") {
      return handleForward;
    }
    return undefined;
  };

  const handleBack = () => {
    if (busy) {
      return;
    }
    console.log({ paymentConfirmed });
    if (paymentConfirmed) {
      return;
    }
    if (
      section?.isPayment &&
      formData.values.quote?.paymentDetails?.status === "succeeded"
    ) {
      return;
    }
    if (submitAlert) {
      setSubmitAlert(undefined);
    }
    const dynamicSection = sections[activeIndex - 1];
    let newActiveIndex = activeIndex;
    if (dynamicSection?.name === "monthlyPaymentConfirmation") {
      if (formData?.values?.paymentFrequency.toUpperCase() !== "MONTHLY") {
        newActiveIndex = activeIndex - 1;
      }
    }
    const newIndex = newActiveIndex - 1;
    if (newIndex >= 0) {
      setActiveIndex(newIndex);
    } else if (props.setActiveJourney) {
      props.setActiveJourney(null);
    }
  };

  const handleForward = () => {
    const dynamicSection = sections[activeIndex + 1];
    let newActiveIndex = activeIndex;
    if (dynamicSection?.name === "monthlyPaymentConfirmation") {
      if (formData?.values?.paymentFrequency.toUpperCase() !== "MONTHLY") {
        newActiveIndex = activeIndex + 1;
      }
    }
    const newIndex = newActiveIndex + 1;
    if (newIndex >= sections.length) {
      return;
    }
    const nextSection = sections[newIndex];
    const nextData: TFormData = getFormData(
      nextSection.fields,
      formData,
      mode,
      config
    );
    setFormData(nextData);
    setActiveIndex(newIndex);
  };

  // -----------------------------------------------------------------------------------------------
  // Rendering helpers
  // -----------------------------------------------------------------------------------------------

  const visibleFields = section?.fields.filter((f) => !f.hidden) || [];
  let allValid = true;
  for (let f of visibleFields) {
    if (!formData.validations[f.name]) {
      allValid = false;
      break;
    }
  }

  const renderSubmitAlert = () => {
    if (!submitAlert) {
      return;
    }
    return (
      <Alert
        severity={submitAlert.severity}
        sx={{ marginTop: 2, border: "1px solid rgba(0,0,0,0.5)" }}
        onClose={() => setSubmitAlert(undefined)}
      >
        {submitAlert.title && <AlertTitle>{submitAlert.title}</AlertTitle>}
        {submitAlert.body}
      </Alert>
    );
  };

  const renderNFSuccess = () => {
    return (
      <Dialog
        open={true}
        scroll="paper"
        fullWidth
        PaperProps={{ sx: { width: "100%", margin: "0px" } }}
      >
        <DialogTitle>Congrats {NFResponse.proposerName}!</DialogTitle>
        <DialogContent className="dialogContent">
          <Confetti />
          <Stack spacing={3}>
            <Alert
              severity="success"
              sx={{ border: "1px solid rgba(0,0,0,0.5)", width: "100%" }}
            >
              {<AlertTitle>Payment Confirmed</AlertTitle>}
              <Stack spacing={2}>
                Your payment details were confirmed successfully!
              </Stack>
            </Alert>
            <p>
              You have now submitted your {config.product.title} application and
              direct debit details, National Friendly will be in touch soon.
            </p>
            <p>
              Your application number is <b>{NFResponse.policyNumber}</b>
            </p>
            <p>
              A welcome email will be sent to <b>{NFResponse.proposerEmail}</b>
            </p>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  const quoteIndex = sections.findIndex((s) => s.isQuote === true);
  const finalIndex = sections.findIndex((s) =>
    config.payment?.provider === "stripe"
      ? formData.values?.paymentFrequency === "MONTHLY"
        ? s.isFinal === true
        : s.isPayment === true
      : s.isFinal === true
  );
  const paymentIndex = sections.findIndex((s) => s.isPayment === true);

  const disablePrePayment =
    activeIndex < paymentIndex && (formData.values?.paymentIntent?.status === "succeeded" || formData.values?.paymentIntent?.status === "requires_payment_method") &&
    formData.values?.quote.state === "QUOTE";

  return (
    <div
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <div style={{ flex: 1 }}>
        <JourneyContext.Provider
          value={{ formData, setFormData, mode, branding }}
        >
          {showMTASuccess && (
            <MTASuccessPage MTA={formData.values.MTA || null} />
          )}
          {!isEmbedded && props.branding && (
            <Branding
              branding={props.branding}
              onBack={getBackHandler()}
              onForward={getForwardHandler()}
            />
          )}
          <JourneyHeader
            index={activeIndex}
            section={section}
            formValues={formData.values}
            config={config}
            disabled={busy || disablePrePayment}
          />
          {NFResponse && renderNFSuccess()}
          {section && (
            <>
              <Container maxWidth="sm" className="journeyContainer">
                <JourneySection
                  submitQuoteUpdate={submitQuoteUpdate}
                  mode={mode}
                  config={config}
                  section={section}
                  formData={formData}
                  changeHandler={changeHandler}
                  disabled={busy || disablePrePayment}
                  indexes={{
                    quote: quoteIndex,
                    active: activeIndex,
                    final: finalIndex,
                  }}
                  paymentComponentRef={paymentComponentRef}
                  setBusy={setBusy}
                  onBack={isEmbedded ? getBackHandler() : undefined}
                />
                {submitAlert && renderSubmitAlert()}
              </Container>
              {!section?.hideSubmitBtn && (
                <SubmitButton
                  key={section.name}
                  onSubmit={handleSubmit}
                  label={
                    (section.isFinal && mode === "MTA") ||
                    (section.isPayment && mode === "MTA")
                      ? "Perform MTA"
                      : replacePlaceholders(
                          formData.values?.quote,
                          section.submitLabel || "Submit"
                        )
                  }
                  disabled={!allValid}
                  loading={busy}
                />
              )}
            </>
          )}
          {(mode === "TEST" || mode === "EDIT") && (
            <DebugView config={config} formData={formData} />
          )}
        </JourneyContext.Provider>
      </div>
      {!isEmbedded && <RegulatoryFooter config={config} formData={formData} />}
    </div>
  );
};

function preprocessInitialValues(initialValues: IStringIndex<any>) {
  for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
    if (typeof fieldValue === "string") {
      if (fieldValue.startsWith("$NOW")) {
        const dateFormat = fieldValue.match(/\((.*?)\)/);
        const today = moment().format(
          dateFormat ? dateFormat[1] : "DD-MM-YYYY"
        );
        initialValues[fieldName] = today;
      }
    }
  }
}

function getPipelineStage(activeIndex: number, sections: TJourneySection[]) {
  enum PipelineStages {
    LeadCaptured = "Lead Captured",
    MinimumQuestionsToQuote = "Minimum Questions to quote",
    Quote = "Quote",
    ExclusionsUnderwriting = "Exclusion & underwriting",
    Finalisation = "Finalisation",
  }
  const quoteIndex = sections.findIndex((s) => s.isQuote === true);
  const finalIndex = sections.findIndex((s) => s.isFinal === true);
  const paymentIndex = sections.findIndex((s) => s?.isPayment === true);
  const section = sections[activeIndex];

  let stage: PipelineStages = PipelineStages.LeadCaptured;

  if (!section) return stage;

  switch (activeIndex) {
    case 0:
      stage = PipelineStages.LeadCaptured;
      break;
    case quoteIndex:
      stage = PipelineStages.Quote;
      break;
    case finalIndex:
    case paymentIndex:
      stage = PipelineStages.Finalisation;
      break;
    default:
      const finalSections = [
        "policyDatesDetails",
        "addressDetails",
        "dateOfBirth",
        "Bill payer",
        "Bank Details",
        "Final Summary",
        "dateOfBirth",
        "addressDetails",
      ];
      if (finalSections.includes(section.name)) {
        stage = PipelineStages.Finalisation;
      } else if (activeIndex < quoteIndex) {
        stage = PipelineStages.MinimumQuestionsToQuote;
      } else if (activeIndex < finalIndex) {
        stage = PipelineStages.ExclusionsUnderwriting;
      }
      break;
  }

  // console.log(`STAGE => ${stage} on ${section.name}`);
  return stage;
}
