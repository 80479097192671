import { Card, IconButton, Stack, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { TFormField, TFormData } from "../../../types";
import { getFieldOptions } from "../../../formHandling";

export const ArrayFieldItem = (props: {
  index: number;
  value: any;
  field: TFormField;
  formData: TFormData;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}) => {
  const { field, index, value, formData, onEdit, onDelete } = props;
  const values = value;
  const children = field.fields ?? [];

  const handleEdit = () => {
    onEdit(index);
  };

  const handleDelete = () => {
    onDelete(index);
  };

  const valueOf = (field: TFormField) => {
    const value = values[field.name] ?? "";
    if (typeof value === "object") {
      if (!Array.isArray(value)) {
        return Object.values(value).join(", ").replaceAll(", ,", ",");
        // return "{}";
      }
      if (value.length === 1) {
        return `1 ${field.arrayParams?.itemTitle ?? "Item"}`;
      } else {
        return `${value.length} ${
          field.arrayParams?.itemTitlePlural ?? field.arrayParams?.itemTitle + "s" ?? "Items"
        }`;
      }
    }
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (field.variant === "money" || field.type === "currency") {
      return "£" + Number(value).toLocaleString("en-GB");
    }
    if (field.options || field.dataSource) {
      const opts = getFieldOptions(field, formData);
      for (let o of opts) {
        if (o.value === value) {
          return o.label;
        }
      }
    }
    return value;
  };

  const renderChildren = (children: TFormField[]) => {
    return children.map(
      (f) =>
        field.arrayParams!.show.includes(f.name) &&
        values[f.name] !== undefined && (
          <Stack
            justifyContent="space-between"
            alignItems="start"
            sx={{ margin: "5px 0 5px 0" }}
            key={f.name}
          >
            <p style={{ flex: 1 }}>{f.title ?? f.label ?? f.name}</p>
            {<b style={{ flex: 1, textAlign: "end" }}>{valueOf(f)}</b>}
          </Stack>
        )
    );
  };

  return (
    <>
      <div>{renderChildren(children)}</div>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Tooltip title="Edit">
            <IconButton color="primary" component="span" onClick={handleEdit}>
              <EditOutlinedIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Tooltip title="Delete">
            <IconButton color="primary" component="span" onClick={handleDelete}>
              <DeleteForeverOutlinedIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
};
