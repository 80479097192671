// =================================================================================================
// Default brand and theme
// =================================================================================================

import { createTheme } from '@mui/material/styles';
import { TBranding } from './types';

export const defaultBrand: TBranding = {
  name: 'Cool Default Brand',
  website: 'https://www.google.com',
  main_color: '#DD0000',
  full_logo: 'img/branding/cooldefaultbrand.png',
  servicing_email: 'support@email.com',
  servicing_phone: '01234567890',
};

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: defaultBrand.main_color,
    },
  },
});

export const tempBranding: TBranding = {
  name: '',
  website: '#',
  main_color: '#3B90CF',
  full_logo: '',
  servicing_email: '',
  servicing_phone: '',
};
