import { createContext } from "react";
import { TFormData, TFormField, TJourneyConfig } from "../../types";

type TEditorContext = {
  saveField: (props: { new?: TFormField; old?: TFormField }) => void;
  formData: TFormData;
  config: TJourneyConfig;
};

export const EditorContext = createContext<TEditorContext>({} as TEditorContext);
