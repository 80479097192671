/* eslint-disable no-template-curly-in-string */
// ============================================================================
// Provide all fields with the "fieldBase" className
// ============================================================================

import { HelpOutline } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import {
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneyMode,
} from "../../types";

export const FieldBase = (props: {
  children: any;
  field: TFormField;
  mode?: TJourneyMode;
  formData?: TFormData;
  config?: TJourneyConfig;
}) => {
  const { children } = props;
  if (props.field.tooltip) {
    if (props.field.label) {
      return (
        <>
          <Stack direction="row" spacing={1} alignItems="top">
            <div style={{ flex: 1 }}>{props.field.label}</div>
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {props.field.tooltip}
                </div>
              }
              disableFocusListener
              arrow
            >
              <HelpOutline color="primary" />
            </Tooltip>
          </Stack>
          <div className="fieldBase">{children}</div>
        </>
      );
    } else {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <div className="fieldBase" style={{ flex: 1 }}>
            {children}
          </div>
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {props.field.tooltip}
              </div>
            }
            disableFocusListener
            arrow
          >
            <HelpOutline color="primary" />
          </Tooltip>
        </Stack>
      );
    }
  } else {
    return (
      <div className="fieldBase">
        {props.field.label &&
          (props.field.label.includes("${productName}") ? (
            <p className="fieldLabel">
              {props.field.label.replace(
                "${productName}",
                props.config?.product.title ?? ""
              )}
            </p>
          ) : (
            <p className="fieldLabel">{props.field.label}</p>
          ))}
        {props.field?.subLabel && (
          <p className="fieldLabel">{props.field?.subLabel}</p>
        )}
        {children}
      </div>
    );
  }
};
