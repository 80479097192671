import moment from "moment";
import { doDateFieldValidation } from "./components/FormFields/DateField";
import { doNumberFieldValidation } from "./components/FormFields/NumberField";
import { doTextFieldValidation } from "./components/FormFields/TextField";
import { doVehicleFieldValidation, TVehicle } from "./components/FormFields/Vehicle/VehicleField";
import { doDirectDebitFieldValidation } from "./components/FormFields/DirectDebit";
import { IStringIndex, TFormField, TVehicleData } from "./types";
import { doLicenceFieldValidation } from "./components/FormFields/LicenceField";
import { doVehicleDriverAssignmentsValidation } from "./components/FormFields/VehicleDriverAssignments";
import { doModificationsFieldValidation } from "./components/FormFields/ModificationsField";

export const isValidPostcode = (postcode: string) => {
  const valid =
    !postcode.length || /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(postcode.toUpperCase());
  return valid;
};

export const isBlacklistedPostcode = (postcode: string, blacklist: string[]) => {
  const c = postcode.trim().toUpperCase();
  for (let b of blacklist) {
    if (c.startsWith(b.toUpperCase())) {
      return true;
    }
  }
  return false;
};

export const validateField = (
  field: TFormField,
  value: any,
  formValues: IStringIndex<any>
): boolean => {
  if (field.hidden) {
    return true;
  }

  switch (field.type) {
    // Simple fields
    case "text":
      return validateTextField(field, value);
    case "option":
    case "select":
    case "autocomplete":
      return validateSimpleField(field, value);
    case "number":
    case "currency":
      return validateNumberField(field, value, formValues);
    case "date":
      return validateDateField(field, value, formValues);
    // Specific behaviour fields
    case "blocker":
      return false;
    case "switch":
      return validateSwitchField(field, value);
    case "switchInfo":
      return validateSwitchField(field, value);
    // Complex fields
    case "address":
      return validateAddressField(field, value);
    case "vehicle":
      return validateVehicleField(field, value);
    case "measurements":
      return validateMeasurementsField(field, value);
    case "appliances":
      return validateAppliancesField(field, value);
    case "premiumSumAssured":
      return validatePremiumSumAssuredField(field, value);
    case "directDebit":
      return validateDirectDebitField(field, value);
    case "array":
      return Array.isArray(value) && value.length > 0;
    case "licence":
      return validateLicenceField(field, value);
    case "vehicleDriverAssignments":
      return doVehicleDriverAssignmentsValidation(field, value);
    case "modifications":
      return doModificationsFieldValidation(field, value).valid;
    case "vehicleERS": {
      return Boolean(value);
    }
  }
  return true;
};

const validatePremiumSumAssuredField = (field: TFormField, value: any) => {
  return value?.sumAssured !== undefined && value?.premium !== undefined;
};

const validateAppliancesField = (field: TFormField, value: any) => {
  if (!value?.list?.length) {
    return false;
  }
  if (value.total > value.limit || value.total === 0) {
    return false;
  }
  return true;
};

const validateSimpleField = (field: TFormField, value: any) => {
  if (field.required) {
    return value !== undefined;
  }
  return true;
};

const validateTextField = (field: TFormField, value: any) => {
  const validation = doTextFieldValidation(field, value);
  return validation.valid;
};

const validateLicenceField = (field: TFormField, value: any) => {
  const validation = doLicenceFieldValidation(field, value);
  return validation.valid;
};

const validateNumberField = (field: TFormField, value: any, formValues: IStringIndex<any>) => {
  const validation = doNumberFieldValidation(field, value + "", formValues);
  return validation.valid;
};

const validateDateField = (field: TFormField, value: any, formValues: IStringIndex<any>) => {
  const validation = doDateFieldValidation(field, value, formValues);
  return validation.valid;
};

const validateSwitchField = (field: TFormField, value: boolean) => {
  const onValue = field.switchParams?.onValue ?? true;
  if (!field.required) {
    return true;
  }
  return value === onValue;
};

const validateAddressField = (field: TFormField, value: any): boolean => {
  return (
    (value?.line1 || "").trim().length > 0 &&
    (value?.town || "").trim().length > 0 &&
    (value?.postcode || "").trim().length > 0 &&
    isValidPostcode(value?.postcode || "") &&
    !isBlacklistedPostcode(value?.postcode || "", field.addressParams?.postCodeBlacklist ?? [])
  );
};

const validateVehicleField = (field: TFormField, value: TVehicle): boolean => {
  return doVehicleFieldValidation(value ?? {}, field.vehicleParams);
};

const validateMeasurementsField = (field: TFormField, value: any): boolean => {
  if (value?.weight !== undefined && value?.height !== undefined) {
    return value.weight > 0 && value.height;
  }
  return false;
};

const validateDirectDebitField = (field: TFormField, value: any): boolean => {
  return doDirectDebitFieldValidation(field, value);
};
