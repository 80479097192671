// =================================================================================================
// This is our wrapper for the MUI component of the same name
// =================================================================================================

import { InputAdornment, TextField as TextFieldMUI } from "@mui/material";
import { TFieldChangeHandler, TFormField, TValidationResult } from "../../types";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import validator from "validator";
import { useState } from "react";
import { isValidPostcode } from "../../fieldValidation";

// =================================================================================================
// Helper functions
// =================================================================================================

const getInputProps = (field: TFormField) => {
  switch (field.variant) {
    case "phone":
      return { maxLength: 11 };
    case "signature":
      return { style: { fontFamily: "'Dancing Script', cursive", fontSize: 20 } };
    default:
      return undefined;
  }
};

const getAdornments = (field: TFormField) => {
  switch (field.variant) {
    case "email":
      return {
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      };
    case "phone": {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <PhoneIcon />
          </InputAdornment>
        ),
      };
    }
    default:
      return undefined;
  }
};

// =================================================================================================
// Validation
// =================================================================================================

export const doTextFieldValidation = (field: TFormField, value: string): TValidationResult => {
  if (!value || value.trim().length === 0) {
    // Required fields cannot be blank
    if (field.required) {
      return { valid: false, errorMsg: "This field is required." };
    } else {
      return { valid: true };
    }
  } else {
    if (
      (field.variant === "alphabet" || field.variant === "signature") &&
      !validator.isAlpha(value, "en-GB", { ignore: " -'" })
    ) {
      return {
        valid: false,
        errorMsg: `Please enter only alphabet characters.`,
      };
    }

    if (field.variant === "numeric" && !validator.isNumeric(value.trim())) {
      return {
        valid: false,
        errorMsg: "Please enter only numeric values.",
      };
    }

    if (field.variant === "sort") {
      if (value.trim().length !== 6) {
        return {
          valid: false,
          errorMsg: "Please enter the 6-digit sort code without hyphens, e.g. 123456.",
        };
      } else {
        return { valid: true };
      }
    }

    if (field.variant === "postcode") {
      if (isValidPostcode(value.trim())) {
        return { valid: true };
      } else {
        return { valid: false, errorMsg: "Postcode is invalid." };
      }
    }

    const min = field.params?.min as number | undefined;
    const max = field.params?.max as number | undefined;
    // Enforce range (length has both minimum and maximum)
    if (min !== undefined && max !== undefined && (value.length > max || value.length < min)) {
      return {
        valid: false,
        errorMsg: `Please enter ${min} to ${max} characters.`,
      };
    }
    // Enforce maximum length
    if (max !== undefined && value.length > max) {
      return {
        valid: false,
        errorMsg: `Please enter ${max} characters or fewer.`,
      };
    }
    // Enforce minimum length
    if (min !== undefined && value.length < min) {
      return {
        valid: false,
        errorMsg: `Please enter ${min} characters or more.`,
      };
    }
    // Email address validation
    if (field.variant === "email") {
      if (!validator.isEmail(value)) {
        return { valid: false, errorMsg: "Please enter a valid email address." };
      }
    }
    // Phone number validation
    if (field.variant === "phone") {
      // couldn't find a good regex for this
      if (
        !validator.isNumeric(value) ||
        value.startsWith("00") ||
        !value.startsWith("0") ||
        value.length < 11 ||
        /\s/.test(value)
      ) {
        return {
          valid: false,
          errorMsg: "Please enter a valid phone number 0xxxxxxxxxx.",
        };
      }
    }
    // This string is perfectly fine. Probably.
    return { valid: true };
  }
};

// =================================================================================================
// Main component
// =================================================================================================

export const TextField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const { field, changeHandler } = props;
  const [value, setValue] = useState(props.value);
  const [validationError, setValidationError] = useState<string | undefined>();

  // -----------------------------------------------------------------------------------------------
  // Component change handler
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (newValue: string) => {
    setValue(newValue);
    const validation = doTextFieldValidation(field, newValue);
    if (validation.valid) {
      changeHandler(field, newValue);
      if (validationError?.length) {
        setValidationError(undefined);
      }
    } else {
      setValidationError(validation.errorMsg);
      changeHandler(field, undefined);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <TextFieldMUI
      disabled={field.disabled}
      fullWidth
      label={field.title}
      variant="outlined"
      InputProps={getAdornments(field)}
      multiline={field.variant === "multiline"}
      rows={field.variant === "multiline" ? 4 : 1}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        localChangeHandler(event.target.value)
      }
      inputProps={getInputProps(field)}
      required={field.required}
      error={Boolean(validationError)}
      helperText={validationError || ""}
      value={value || ""}
    />
  );
};
