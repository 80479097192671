import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import { TFormField, TJourneyConfig } from "../types";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { renderObject, THtmlObject } from "../renderHTML";
import { JourneyContext } from "../JourneyContext";
import { getLinks } from "../formHandling";

export const DialogBox = (props: {
  field: TFormField;
  config?: TJourneyConfig;
  open: boolean;
  onClose: () => void;
}) => {
  const journeyContext = useContext(JourneyContext);
  const formData = journeyContext.formData;

  const theme = useTheme();
  const { field, config, open, onClose } = props;

  const renderBenefitsDialog = () => {
    if (!config) {
      return null;
    }

    const links = getLinks(config.info, formData);

    return (
      <Dialog
        open={open}
        scroll="paper"
        fullWidth
        PaperProps={{ sx: { width: "100%", margin: "0px" } }}
      >
        <DialogTitle>{field.dialog?.title ?? "Summary of Cover and Policy Documents"}</DialogTitle>
        <DialogContent className="dialogContent">
          {config.info?.benefits &&
            config.info.benefits.map((benefit) => (
              <Stack direction="row" alignItems="top" spacing={2} key={benefit}>
                <CheckIcon sx={{ color: "green" }} />
                <span>{benefit}</span>
              </Stack>
            ))}
          {config.info?.antiBenefits &&
            config.info.antiBenefits.map((antiBenefit) => (
              <Stack direction="row" alignItems="top" spacing={2} key={antiBenefit}>
                <ClearIcon sx={{ color: "red" }} />
                <span>{antiBenefit}</span>
              </Stack>
            ))}
            <br/>
          {config.info?.services_pay_message &&
            config.info.services_pay_message.map((text, index) => (
              <div>     
              {index === 0 ? <p style={{fontWeight: 'bold'}}>{text}</p>: <p>{text}</p>}
              <br/>
              </div>
            ))}
          {config.info?.services_pay_message &&
            <table>
              <tr>
                <td style={{fontWeight: 'bold'}}>Type of transaction</td>
                <td></td>
              </tr>
              <tr>
                <td>Quotation or arranging your insurance</td>
                <td>Nil</td>
              </tr>
              <tr>
                <td>Making any changes to your policy</td>
                <td>£25</td>
              </tr>
              <tr>
                <td>Cancelling your policy after the cooling off period has ended(*):</td>
                <td>£30</td>
              </tr>
            </table>
  }

            <br/>
            <p> * The cooling off period is the first 14-days of the period of insurance.</p>
          <br />
          {links && links.length > 0 && (
            <>
              <p>For full product information, please read the documents below.</p>
              <br />
              {links.map((link) => (
                <Stack
                  direction="row"
                  alignItems="top"
                  spacing={2}
                  key={link.title + "-" + link.url}
                >
                  <OpenInNewIcon sx={{ color: theme.palette.primary.main }} />
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: theme.palette.primary.main, textDecoration: "none" }}
                  >
                    {link.title}
                  </a>
                </Stack>
              ))}
            </>
          )}
        </DialogContent>
        <div style={{ padding: 16 }}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              borderRadius: 15,
              textTransform: "none",
              fontSize: 18,
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </Dialog>
    );
  };

  const renderCustomDialog = () => {
    const renderHTML = () => {
      if (typeof field.dialog.content === "string") {
        return <div dangerouslySetInnerHTML={{ __html: field.dialog.content }} />;
      }

      if (typeof field.dialog.content === "object") {
        return <div>{renderObject(field.dialog.content as THtmlObject)}</div>;
      }

      return null;
    };

    return (
      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        fullWidth
        PaperProps={{ sx: { width: "100%", margin: "0px" } }}
      >
        <DialogTitle id="alert-dialog-title">{field.dialog.title}</DialogTitle>
        <DialogContent className="dialogContent">{renderHTML()}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus className="dialogCloseButton">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return field.variant === "benefits" ? renderBenefitsDialog() : renderCustomDialog();
};
