import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { EditorDialog } from "./EditorDialog";
import DataObjectIcon from "@mui/icons-material/DataObject";

export const APIEditor = (props: {
  name: string;
  originalAPI: any;
  onSave: (api: any, name: string) => void;
}) => {
  const { name, originalAPI, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editAPI, setEditAPI] = useState(JSON.stringify(originalAPI, null, 2));

  const applyChanges = () => {
    try {
      const parsed = JSON.parse(editAPI);
      setEditAPI(JSON.stringify(parsed, null, 2));
      onSave(parsed, name);
      closeDialog();
    } catch (e) {
      alert(e);
    }
  };

  const discardChanges = () => {
    closeDialog();
    setEditAPI(JSON.stringify(originalAPI, null, 2));
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const renderInputs = () => {
    return (
      <TextField
        value={editAPI}
        multiline
        fullWidth
        rows={20}
        onChange={(event) => setEditAPI(event.target.value)}
        InputProps={{
          style: {
            fontFamily: "monospace",
            backgroundColor: "#E1E1E1",
            color: "#1E1E1E",
            padding: 4,
          },
        }}
      ></TextField>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button onClick={openDialog} variant="contained" fullWidth startIcon={<DataObjectIcon />}>
        {name}
      </Button>
    </div>
  );
};
