import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";

import {
  Button,
  Card,
  Stack,
  TextField,
  TextFieldProps
} from "@mui/material";
import moment from "moment";
import { useState, useContext } from "react";
import { TVehicleParams } from "../../../types";
import { TVehicle, isBlacklisted } from "./VehicleField";
import { activeJourneyContext } from "../../../activeJourneyContext";

export const VehicleDetails = (props: {
  vehicle: TVehicle;
  vehicleParams?: TVehicleParams;
  onChange: () => void;
  disabled?: boolean;
  onRegistrationChange: (registrationDate?: string) => void;
}) => {
  const activeJourney = useContext(activeJourneyContext)
  console.log('activeJourney', activeJourney)
  const { vehicle, onChange, onRegistrationChange, vehicleParams } = props;
  const [registrationDateError, setRegistrationDateError] = useState("");
  const [registrationDate, setRegistrationDate] = useState(vehicle.registration_date);
  const [prefilled, setPrefilled] = useState(Boolean(vehicle.registration_date));

  const match = vehicle.vehicle_year ? vehicle.vehicle_year.match(/(19|20)\d{2}/g) : null;
  const vehicleYear = match && match.length === 1 ? match[0] : undefined;

  const maxVehicleAge = vehicleParams?.maxModelAge;
  const maxRegistrationAge = vehicleParams?.maxRegistrationAge;

  const blacklisted = vehicleParams?.blacklist
    ? isBlacklisted(vehicle, vehicleParams.blacklist)
    : false;

  const vehicleAge = vehicleYear ? moment().diff(vehicleYear, "years") : undefined;
  const regAge = vehicle.registration_date
    ? moment().diff(moment(vehicle.registration_date, "DD-MM-YYYY"), "years")
    : undefined;

  const registrationDateChanged = (newValue: any, text: string | undefined) => {
    if (newValue === null) {
      setRegistrationDate(undefined);
      setRegistrationDateError("This field is required.");
      onRegistrationChange(undefined);
      return;
    }
    setRegistrationDate(newValue.format("DD-MM-YYYY"));
    if (!newValue._isValid) {
      setRegistrationDateError("Please enter a valid date.");
      onRegistrationChange(undefined);
    } else if (newValue.isAfter(moment())) {
      setRegistrationDateError("Date cannot be in the future.");
      onRegistrationChange(undefined);
    } else if (
      maxRegistrationAge !== undefined &&
      moment().diff(newValue, "years") > maxRegistrationAge
    ) {
      setRegistrationDateError(`Cannot be longer than ${maxRegistrationAge} years ago.`);
      onRegistrationChange(undefined);
    } else if (vehicleYear !== undefined && newValue.isBefore(moment(vehicleYear))) {
      setRegistrationDateError(`Cannot be older than vehicle year of manufacture.`);
      onRegistrationChange(undefined);
    } else {
      setRegistrationDateError("");
      onRegistrationChange(newValue.format("DD-MM-YYYY"));
    }
  };

  const renderRegistrationDateInput = () => {
    return (
      <Stack spacing={2}>
        <p>When was the vehicle registered?</p>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            disabled={props.disabled}
            label={"Registration Date"}
            disableFuture
            //minDate={value?.year ? moment(value.year) : undefined}
            maxDate={moment()}
            mask="__-__-____"
            inputFormat="DD-MM-YYYY"
            value={registrationDate ? moment(registrationDate, "DD-MM-YYYY") : null}
            allowSameDateSelection
            onChange={registrationDateChanged}
            renderInput={(params: TextFieldProps) => (
              <TextField
                required
                fullWidth
                variant="outlined"
                {...params}
                helperText={registrationDateError}
                error={Boolean(registrationDateError)}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
    );
  };

  let vehicleData = [
    { label: "VRM", value: vehicle.registration_number },
    { label: "Make", value: vehicle.vehicle_make },
    { label: "Colour", value: vehicle.vehicle_colour },
    { label: "Range", value: vehicle.vehicle_range },
    { label: "Model", value: vehicle.vehicle_model },
    { label: "Year", value: vehicle.vehicle_year }
  ];
  
  const isScratchAndDent1Edge = activeJourney?.product.id === 'pro-e81ce45f-6909-485f-81d7-f7935065446d'

  if(!isScratchAndDent1Edge){
    vehicleData = [
    ...vehicleData,    
    { label: "Doors", value: vehicle.num_of_doors },
    { label: "Transmission", value: vehicle.transmission },
    { label: "Fuel", value: vehicle.fuel_type },
    { label: "Engine", value: vehicle.engineCC },
    { label: "Trim", value: vehicle.vehicle_trim }
  ]
  }

  return (
    <Stack spacing={3}>
      <Card sx={{ padding: 2, borderRadius: 2 }}>
        <Stack spacing={1}>
          {vehicleData.map((item) => (
            <Stack direction="row" key={item.label}>
              <p style={{ width: 140 }}>{item.label}</p>
              <b>{item.value}</b>
            </Stack>
          ))}
        {prefilled && !isScratchAndDent1Edge && (
            <Stack direction="row">
              <p style={{ width: 140 }}>Registered</p>
              <b>{vehicle.registration_date}</b>
            </Stack>
          )}
          <br />
          <Button
            variant="outlined"
            onClick={onChange}
            disabled={props.disabled}
            style={{ textTransform: "none" }}
          >
            Change vehicle
          </Button>
        </Stack>
      </Card>
      {!prefilled && renderRegistrationDateInput()}
      {vehicleAge !== undefined && maxVehicleAge !== undefined && vehicleAge > maxVehicleAge && !isScratchAndDent1Edge && ( 
          <b>
          To qualify for this product, your vehicle must not be older than {maxVehicleAge} years.
        </b>
      )}
      {regAge !== undefined && maxRegistrationAge !== undefined && regAge > maxRegistrationAge && (
        <b>Registration date cannot be older than {maxRegistrationAge} years</b>
      )}
      {regAge !== undefined && vehicleAge !== undefined && regAge > vehicleAge && (
        <b>Vehicle registration cannot be before the year of manufacture.</b>
      )}

      {blacklisted && <b>This vehicle make or model is not eligible for cover.</b>}
      {vehicleAge === undefined && <b>The age of this vehicle is unknown.</b>}
    </Stack>
  );
};
