import { Tooltip } from "@mui/material";
import { TFormField } from "../../types";
import { HelpOutline } from "@mui/icons-material";

export const MessageField = (props: { field: TFormField }) => {
  const insertComponent = (
    text?: string,
    position?: string,
    element?: string
  ) => {
    if (position && element) {
      const positionIndex = text?.indexOf(position);
      if (positionIndex && positionIndex !== -1) {
        const positionEndIndex = positionIndex + position.length;
        const beforeText = text?.slice(0, positionEndIndex);
        const afterText = text?.slice(positionEndIndex);
        const Component = getComponentByName(element, props.field);
        return (
          <>
            {beforeText}
            {Component}
            {afterText}
          </>
        );
      }
    }
    return text;
  };

  const processedText = insertComponent(
    props.field.title,
    props.field?.props?.insertElement?.position,
    props.field?.props?.insertElement?.element
  );

  return <b style={props.field.style}>{processedText}</b>;
};

const getComponentByName = (name: string, field: TFormField) => {
  switch (name) {
    case "tooltip":
      const content = field.props.insertElement.content;
      return (
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {Array.isArray(content)
                ? content.map((text) => <p>{text}</p>)
                : content}
            </div>
          }
          disableFocusListener
          arrow
        >
          <HelpOutline color="primary" />
        </Tooltip>
      );
    // Add more cases for other component names
    default:
      return null;
  }
};
