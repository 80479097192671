import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";

export type TActiveButton = "EDIT" | "PREVIEW";

export const TopButtons = (props: {
  activeButton: TActiveButton;
  setActiveButton: (active: TActiveButton) => void;
}) => {
  const { activeButton, setActiveButton } = props;
  return (
    <ToggleButtonGroup
      color="primary"
      value={activeButton}
      exclusive
      onChange={(event, newValue) => setActiveButton(newValue)}
    >
      <ToggleButton value="EDIT">
        <EditIcon sx={{ marginRight: 2 }} />
        Edit
      </ToggleButton>
      <ToggleButton value="PREVIEW">
        <PreviewIcon sx={{ marginRight: 2 }} /> Preview
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
