import { Stack, Button, Card, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { TJourneySection } from "../../types";
import { EditorDialog } from "./EditorDialog";
import DataArrayIcon from "@mui/icons-material/DataArray";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const SectionsManager = (props: {
  originalSections: TJourneySection[];
  onSave: (sections: TJourneySection[]) => void;
  setActiveIndex: (index: number) => void;
}) => {
  const { setActiveIndex, onSave } = props;
  const { originalSections } = props;
  const [editSections, setEditSections] = useState(originalSections);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    console.log("Sections Manager Props Changed");
    setEditSections(props.originalSections);
  }, [props]);

  const applyChanges = () => {
    closeDialog();
    onSave(editSections);
  };

  const discardChanges = () => {
    closeDialog();
    setEditSections(originalSections);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const deleteSection = (index: number) => {
    const newSections = [...editSections];
    newSections.splice(index, 1);
    setEditSections(newSections);
  };

  const blankSection = {
    name: "",
    heading: "",
    description: "",
    submitLabel: "",
    fields: [],
  };

  // const insertSectionBefore = () => {
  //   const newSections = [...sections];
  //   if (activeIndex === 0) {
  //     newSections.unshift(blankSection);
  //   } else {
  //     newSections.splice(activeIndex, 0, blankSection);
  //   }
  //   const newConfig = { ...config, sections: newSections };
  //   setConfig(newConfig);
  // };

  // const insertSectionAfter = () => {
  //   const newSections = [...sections];
  //   if (activeIndex === newSections.length - 1) {
  //     newSections.push(blankSection);
  //   } else {
  //     newSections.splice(activeIndex + 1, 0, blankSection);
  //   }
  //   const newConfig = { ...config, sections: newSections };
  //   setConfig(newConfig);
  //   handleForward();
  // };

  const moveSectionUp = (index: number) => {
    const A = { ...editSections[index - 1] };
    const B = { ...editSections[index] };
    const newSections = [...editSections];
    newSections[index - 1] = B;
    newSections[index] = A;
    setEditSections(newSections);
  };

  const moveSectionDown = (index: number) => {
    const A = { ...editSections[index + 1] };
    const B = { ...editSections[index] };
    const newSections = [...editSections];
    newSections[index + 1] = B;
    newSections[index] = A;
    setEditSections(newSections);
  };

  const renderSection = (section: TJourneySection, index: number) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} key={section.name + "-" + index}>
        <Card sx={{ padding: 1, flex: 1 }} variant="outlined">
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
            <p style={{ width: 30 }}>[{index}]</p>

            <Stack spacing={1} sx={{ flex: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                <b style={{ width: 100 }}>Name</b>
                <p style={{ flex: 1 }}>{section.name || "(unnamed)"}</p>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                <b style={{ width: 100 }}>Heading</b>
                <p style={{ flex: 1 }}>{section.heading || "(no heading)"}</p>
              </Stack>
            </Stack>
            <Stack>
              <IconButton
                disabled={index === 0}
                color="primary"
                onClick={() => moveSectionUp(index)}
              >
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton
                disabled={index === editSections.length - 1}
                color="primary"
                onClick={() => moveSectionDown(index)}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </Stack>
            <Stack>
              <IconButton
                color="primary"
                onClick={() => {
                  applyChanges();
                  setActiveIndex(index);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={() => deleteSection(index)}>
                <DeleteForeverIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    );
  };

  const addSection = () => {
    setEditSections([...editSections, blankSection]);
  };

  const renderInputs = () => {
    return (
      <Stack spacing={3}>
        {editSections.map((section, index) => renderSection(section, index))}
        <div>
          <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={addSection}>
            Add Section
          </Button>
        </div>
      </Stack>
    );
  };

  return (
    <div>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button size="small" onClick={openDialog} variant="contained" startIcon={<DataArrayIcon />}>
        Sections
      </Button>
    </div>
  );
};
