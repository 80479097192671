import { Button, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { HTMLField } from "../FormFields/HTMLField";

function HtmlToJson() {
  const [htmlString, setHtmlString] = useState("");
  const [jsonObject, setJsonObject] = useState<any>(null);

  function handleInputChange(event: any) {
    setHtmlString(event.target.value);
  }

  function handleClick() {
    const trimmedString = htmlString.trim();

  // Replace consecutive empty spaces and new line characters within the text content with a single space
  const cleanedString = trimmedString.replace(/\s+/g, ' ');
    const json = htmlToJson(cleanedString);
    setJsonObject(json);
  }

  return (
    <Container
      sx={{
        margin: 2,
        display: "flex",
        flexDirection: "column",
        gap: 5,
      }}
    >
      <Typography>Convert HTML content to JSON to be used in journey config.</Typography>
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <TextField
          fullWidth
          maxRows={10}
          multiline
          label="Enter HTML string"
          value={htmlString}
          onChange={handleInputChange}
        />
      </div>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Convert to JSON
      </Button>
      {jsonObject && (
        <>
          <Typography color={"#1976D2"}>JSON</Typography>
          <pre className="json-viewer">{JSON.stringify(jsonObject, null, 2)}</pre>
        </>
      )}
      {jsonObject && (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Typography color={"#1976D2"}>Field Preview</Typography>
          <HTMLField field={jsonObject} />
        </div>
      )}
    </Container>
  );
}

export default HtmlToJson;

function htmlToJson(htmlString: string) {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const rootElements = Array.from(doc.body.children);

    const htmlJson = rootElements.map((element) => ({
      type: "html",
      html: parseElement(element),
    }));

    return htmlJson.length > 1
      ? { name: "mainContainer", type: "html", html: htmlJson }
      : htmlJson[0];
  } catch (error: any) {
    console.error(error.message);
  }
  function parseElement(element: any): any {
    try {
      const props: any = {};

      for (let i = 0; i < element.attributes.length; i++) {
        const attr = element.attributes[i];
        if (attr.name === "style") {
          const styleStr = element.getAttribute("style");
          console.log(styleStr);
          const styleArr = styleStr.split(";").map((pair: any) => pair.trim().split(":"));
          const styleObj = styleArr.reduce((acc: any, [key, value]: any) => {
            if (key && value) {
              acc[cssToReactStyleName(key)] = value.trim();
            }
            return acc;
          }, {});
          props.style = styleObj;
          continue;
          // element.style = {...element.style,}
        } else {
          console.log(attr.name);
          props[htmlToReactAttrName(attr.name)] = attr.value;
        }
      }

      if (element.style.length > 0) {
        props.style = {};
        for (let i = 0; i < element.style.length; i++) {
          const styleName = element.style[i];
          props.style[cssToReactStyleName(styleName)] = element.style[styleName];
        }
        // console.log(props.style);
      }

      let children = [];

      if (element.childNodes.length === 0) {
        // if there are no child nodes, return null for children
        return { tag: element.tagName.toLowerCase(), props };
      } else {
        // loop through child nodes and parse them
        for (let i = 0; i < element.childNodes.length; i++) {
          const childNode = element.childNodes[i];
          if (childNode.nodeType === Node.TEXT_NODE) {
            // if it's a text node, push the text to children
            children.push(childNode.textContent);
          } else if (childNode.nodeType === Node.ELEMENT_NODE) {
            // if it's an element node, recursively parse it and push to children
            children.push(parseElement(childNode));
          }
        }
      }

      if (element.tagName === "p" && children.every((child) => typeof child === "string")) {
        props.className = "contentBox";
        props.style = { textAlign: "justify" };
      }

      console.log(props.style);

      return { tag: element.tagName.toLowerCase(), props, children };
    } catch (error: any) {
      console.error("Error parsing element: " + error.message);
    }
  }
}

function htmlToReactAttrName(htmlAttrName: string) {
  if (htmlAttrName.includes("-"))
    return htmlAttrName
      .replace(/^(data|aria)-/, "")
      .replace(/-([a-z])/g, (_, letter: string) => letter.toUpperCase());

  const attributeMap: { [key: string]: string } = {
    class: "className",
    for: "htmlFor",
    tabindex: "tabIndex",
    readonly: "readOnly",
    maxlength: "maxLength",
    minlength: "minLength",
    rowspan: "rowSpan",
    colspan: "colSpan",
    cellspacing: "cellSpacing",
    cellpadding: "cellPadding",
    bgcolor: "bgColor",
    nowrap: "noWrap",
    valign: "vAlign",
    enctype: "encType",
    acceptcharset: "acceptCharset",
    accesskey: "accessKey",
    contenteditable: "contentEditable",
    spellcheck: "spellCheck",
    autocorrect: "autoCorrect",
    autocapitalize: "autoCapitalize",
    autocomplete: "autoComplete",
  };
  return attributeMap[htmlAttrName] ?? htmlAttrName;
}

function cssToReactStyleName(cssName: string) {
  return cssName.replace(/-(.)/g, (_, letter: string) => letter.toUpperCase());
}
