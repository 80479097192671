import { Container } from "@mui/material";
import { useState } from "react";
import { JourneyEditor } from "./components/Editor/JourneyEditor";
import { FileChooser } from "./components/FileChooser";
import { TJourneyConfig } from "./types";
import HtmlToJson from "./components/Editor/HtmlToJson";

export const JourneyEditApp = () => {
  const [config, setConfig] = useState<TJourneyConfig | null>(null);

  const handleSuccess = (obj: any) => {
    setConfig(obj as TJourneyConfig);
  };

  const handleFailure = (obj: any) => {
    alert(obj);
  };

  const handleSave = (savedConfig: string) => {
    console.log(savedConfig);
  };

  if (config === null) {
    return (
      <Container
        maxWidth="sm"
        sx={{ backgroundColor: "white", borderRadius: 4, padding: 2, marginTop: 2 }}
      >
        <FileChooser successHandler={handleSuccess} failureHandler={handleFailure} />
        <HtmlToJson />
      </Container>
    );
  }

  return <JourneyEditor config={config} onSave={handleSave} />;
};
