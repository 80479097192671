// =================================================================================================
// Select an item from a list of options, with autocomplete search.
// =================================================================================================

import { Autocomplete, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getFieldOptions } from "../../formHandling";
import { JourneyContext } from "../../JourneyContext";
import { TFieldChangeHandler, TFormField } from "../../types";

// =================================================================================================
// Main component
// =================================================================================================

export const AutocompleteField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state and initial values
  // -----------------------------------------------------------------------------------------------
  const [validationError, setValidationError] = useState<string | undefined>();
  const { field, changeHandler } = props;
  const journeyContext = useContext(JourneyContext);
  // Options can either be an array of strings, or a { label: string, value: any } object
  const localOptions = getFieldOptions(field, journeyContext.formData);

  // Works differently from other similar MUI components. We have to do this match ourselves.
  const getInitialValue = (initialValue: any) => {
    const match = localOptions.find((option) => option.value === initialValue);
    return match || null;
  };

  const [value, setValue] = useState(getInitialValue(props.value));

  useEffect(() => {
    setValue(getInitialValue(props.value));
  }, [props.value]);

  // -----------------------------------------------------------------------------------------------
  // Change handler and validation
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (selectedOption: any) => {
    setValue(selectedOption);
    if (selectedOption === null) {
      changeHandler(field, undefined);
      if (field.required) {
        setValidationError("This field is required.");
      }
    } else {
      if (validationError) {
        setValidationError(undefined);
      }
      changeHandler(field, selectedOption.value);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <Autocomplete
      disabled={field.disabled}
      value={value}
      onChange={(event: any, selected: any) => {
        localChangeHandler(selected);
      }}
      isOptionEqualToValue={(a, b) => a.value === b.value}
      options={localOptions}
      getOptionDisabled={(opt) => opt.disabled}
      fullWidth
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={field.title}
            InputProps={{
              ...params.InputProps,
            }}
            required={field.required}
            helperText={validationError || ""}
            error={Boolean(validationError)}
          />
        );
      }}
    />
  );
};
