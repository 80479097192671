import { Stack, TextField, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import { TJourneyProduct } from "../../types";
import { EditorContext } from "./EditorContext";
import { EditorDialog } from "./EditorDialog";

export const ProductEditor = (props: {
  originalProduct: TJourneyProduct;
  onSave: (product: TJourneyProduct) => void;
}) => {
  const { originalProduct, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(originalProduct);
  const editorContext = useContext(EditorContext);
  const allSectionNames = editorContext.config.sections.map((section) => section.name);

  const applyChanges = () => {
    onSave(editProduct);
    closeDialog();
  };

  const discardChanges = () => {
    closeDialog();
    setEditProduct(originalProduct);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const changeHeaderSection = (sectionName: string) => {
    if (sectionName) {
      setEditProduct({ ...editProduct, headerSection: sectionName });
    } else {
      setEditProduct({ ...editProduct, headerSection: undefined });
    }
  };

  const renderInputs = () => {
    return (
      <Stack spacing={4}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Product Title"
          value={editProduct.title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditProduct({ ...editProduct, title: event.target.value })
          }
          required={true}
          helperText="The title of the product. Should be similar to the filename."
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          multiline={true}
          rows={3}
          label="Product Description"
          value={editProduct.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditProduct({ ...editProduct, description: event.target.value })
          }
          helperText="An optional text description of the product."
        />
        <FormControl fullWidth>
          <InputLabel id="label">Header Section</InputLabel>
          <Select
            labelId="label"
            value={editProduct.headerSection || ""}
            label="Header Section"
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            size="medium"
            onChange={(event) => changeHeaderSection(event.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {allSectionNames.map((name, index) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  return (
    <div>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button variant="contained" size="small" onClick={openDialog}>
        Product
      </Button>
    </div>
  );
};
