import { Stack, TextField, Button, Divider, IconButton } from "@mui/material";
import { useState } from "react";
import { TJourneyInfo } from "../../types";
import { EditorDialog } from "./EditorDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const InfoEditor = (props: {
  originalInfo: TJourneyInfo;
  onSave: (info: TJourneyInfo) => void;
}) => {
  const { originalInfo, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(originalInfo);

  const benefits = editInfo.benefits || [];
  const links = editInfo.links || [];

  const applyChanges = () => {
    onSave(editInfo);
    closeDialog();
  };

  const discardChanges = () => {
    closeDialog();
    setEditInfo(originalInfo);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const addLink = () => {
    console.log("add link");
    setEditInfo({
      ...editInfo,
      links: [...links, { title: "", url: "" }],
    });
    console.log(editInfo);
  };

  const addBenefit = () => {
    setEditInfo({
      ...editInfo,
      benefits: [...benefits, ""],
    });
  };

  const deleteBenefit = (index: number) => {
    const newBenefits = [...benefits];
    newBenefits.splice(index, 1);
    setEditInfo({ ...editInfo, benefits: newBenefits });
  };

  const deleteLink = (index: number) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setEditInfo({ ...editInfo, links: newLinks });
  };

  const renderBenefit = (benefit: string, index: number) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        key={index}
      >
        <p>[{index}]</p>
        <TextField
          label={`Description`}
          value={benefit || ""}
          fullWidth
          onChange={(event) => updateBenefit(index, event.target.value)}
          size="small"
        />
        <IconButton color="error" onClick={() => deleteBenefit(index)}>
          <DeleteForeverIcon />
        </IconButton>
      </Stack>
    );
  };

  const updateLink = (index: number, part: "TITLE" | "URL", value: string) => {
    const newLinks = [...links];
    newLinks[index] =
      part === "TITLE" ? { ...links[index], title: value } : { ...links[index], url: value };
    setEditInfo({ ...editInfo, links: newLinks });
  };

  const updateBenefit = (index: number, value: string) => {
    const newBenefits = [...benefits];
    newBenefits[index] = value;
    setEditInfo({ ...editInfo, benefits: newBenefits });
  };

  const renderLink = (link: { title: string; url: string }, index: number) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        key={index}
      >
        <p>[{index}]</p>
        <TextField
          label="Title"
          value={link.title || ""}
          fullWidth
          onChange={(event) => updateLink(index, "TITLE", event.target.value)}
          size="small"
        />
        <TextField
          label="URL"
          value={link.url || ""}
          fullWidth
          onChange={(event) => updateLink(index, "URL", event.target.value)}
          size="small"
        />

        <IconButton color="error" onClick={() => deleteLink(index)}>
          <DeleteForeverIcon />
        </IconButton>
      </Stack>
    );
  };

  const renderInputs = () => {
    return (
      <Stack spacing={4}>
        <Divider>BENEFITS</Divider>
        <Stack spacing={2}>
          {benefits.length === 0 && <p>List of benefits is empty.</p>}
          {benefits.map((benefit: string, index: number) => renderBenefit(benefit, index))}
        </Stack>
        <div>
          <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={addBenefit}>
            Add Benefit
          </Button>
        </div>
        <Divider>LINKS</Divider>
        <Stack spacing={2}>
          {links.length === 0 && <p>List of links is empty.</p>}
          {links.map((link: { url: string; title: string }, index: number) =>
            renderLink(link, index)
          )}
        </Stack>
        <div>
          <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={addLink}>
            Add Link
          </Button>
        </div>
        {/* <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Product Title"
          value={editProduct.title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditProduct({ ...editProduct, title: event.target.value })
          }
          required={true}
          helperText="The title of the product. Should be similar to the filename."
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          multiline={true}
          rows={3}
          label="Product Description"
          value={editProduct.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditProduct({ ...editProduct, description: event.target.value })
          }
          helperText="An optional text description of the product."
        /> */}
      </Stack>
    );
  };

  return (
    <div>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button variant="contained" size="small" onClick={openDialog}>
        Benefits
      </Button>
    </div>
  );
};
