// =================================================================================================
// Drop-down field for selecting an option from a list
// =================================================================================================

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useContext, useEffect } from "react";
import { getFieldOptions } from "../../formHandling";
import { JourneyContext } from "../../JourneyContext";
import { TFieldChangeHandler, TFormField } from "../../types";

// =================================================================================================
// Main component
// =================================================================================================

export const SelectField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  const { field, value, changeHandler } = props;
  const journeyContext = useContext(JourneyContext);
  // Options can either be an array of strings, or a { label: string, value: any } object
  const localOptions = getFieldOptions(field, journeyContext.formData);

  const localChangeHandler = (event: any) => {
    changeHandler(field, event.target.value);
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    const selected = localOptions.find((opt) => opt.value === value);
    if (selected?.disabled) {
      changeHandler(field, undefined);
    }
  });

  return (
    <FormControl fullWidth required={field.required}>
      <InputLabel id={field.label + "-label"}>{field.title}</InputLabel>
      <Select
        disabled={field.disabled}
        labelId={field.label + "-label"}
        label={field.title}
        value={value ?? ""}
        onChange={localChangeHandler}
        MenuProps={{
          style: {
            maxHeight: 400,
          },
        }}
      >
        {localOptions.map((option, index) => {
          return (
            <MenuItem sx={{whiteSpace:"pre-wrap",maxWidth:"600px"}} key={index} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
