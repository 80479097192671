import { Button, Container, Divider, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { validateField } from "../../fieldValidation";
import { checkDependents, getFormData } from "../../formHandling";
import {
  IStringIndex,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneyInfo,
  TJourneyProduct,
  TJourneySection,
} from "../../types";
import { substituteForHeadings } from "../../utils";
import { renderFields } from "../FormFields/fields";
import { SubmitButton } from "../SubmitButton";
import { EditorContext } from "./EditorContext";
import { TActiveButton, TopButtons } from "./TopButtons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import { SectionDetailsEditor } from "./SectionDetailsEditor";
import { JourneyContext } from "../../JourneyContext";
import { ProductEditor } from "./ProductEditor";
import { SectionsManager } from "./SectionsManager";
import { InfoEditor } from "./InfoEditor";
import { APIsEditor } from "./APIsEditor";
import { ReferralsEditor } from "./ReferralsEditor";
import { DebugView } from "../DebugView";

// =================================================================================================
// Main component
// =================================================================================================

export const JourneyEditor = (props: {
  config: TJourneyConfig;
  onSave: (savedJourney: string) => void;
}) => {
  const [config, setConfig] = useState(props.config);
  const [activeButton, setActiveButton] = useState<TActiveButton>("EDIT");
  const [formData, setFormData] = useState<TFormData>({
    values: {},
    validations: {},
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const sections = config.sections;
  const section: TJourneySection | undefined =
    activeIndex >= 0 ? sections[activeIndex] : undefined;
  const nextSection: TJourneySection | undefined = sections[activeIndex + 1];
  const editing = activeButton === "EDIT";

  const handleBack = () => {
    setActiveIndex(activeIndex - 1);
  };

  const handleForward = () => {
    if (activeIndex + 1 < sections.length) {
      setActiveIndex(activeIndex + 1);
      const nextData: TFormData = getFormData(
        nextSection.fields,
        formData,
        "EDIT",config
      );
      setFormData(nextData);
    }
  };

  // useEffect(() => {
  //   console.log(JSON.stringify(formData, null, 2));
  // }, [formData]);

  useEffect(() => {
    const getInitialValidations = (initialValues: IStringIndex<any>) => {
      let allFields: TFormField[] = [];
      const validations: IStringIndex<boolean> = {};
      for (let s of config.sections) {
        allFields = [...allFields, ...s.fields];
      }
      for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
        const matchingField = allFields.find(
          (field) => field.name === fieldName
        );
        if (matchingField) {
          if (matchingField.autoClear) {
            validations[fieldName] = false;
          } else {
            validations[fieldName] = validateField(
              matchingField,
              fieldValue,
              formData.values
            );
          }
        }
      }

      for (let f of allFields) {
        delete f.dependents;
      }

      return validations;
    };

    const initialValidations = config.initialValues
      ? getInitialValidations(config.initialValues)
      : {};

    const newSection = sections[activeIndex];
    if (!newSection) {
      return;
    }
    const newFormData = getFormData(
      newSection.fields,
      {
        values: config.initialValues || {},
        validations: initialValidations,
      },
      "EDIT",config
    );
    setFormData(newFormData);
  }, [config, activeButton]);

  // useEffect(() => {
  //   console.log("Journey config has changed");
  //   if (!config?.sections) {
  //     return;
  //   }

  //   const getInitialValidations = (initialValues: IStringIndex<any>) => {
  //     let allFields: TFormField[] = [];
  //     const validations: IStringIndex<boolean> = {};
  //     for (let s of config.sections) {
  //       allFields = [...allFields, ...s.fields];
  //     }
  //     for (let [fieldName, fieldValue] of Object.entries(initialValues)) {
  //       const matchingField = allFields.find((field) => field.name === fieldName);
  //       if (matchingField) {
  //         if (matchingField.autoClear) {
  //           validations[fieldName] = false;
  //         } else {
  //           validations[fieldName] = validateField(matchingField, fieldValue);
  //         }
  //       }
  //     }
  //     return validations;
  //   };

  //   const initialValidations = config.initialValues
  //     ? getInitialValidations(config.initialValues)
  //     : {};

  //   if (activeIndex === -1) {
  //     const newIndex = 0;
  //     // Go to first section
  //     setActiveIndex(newIndex);
  //     const newSection = sections[newIndex];
  //     const newFormData = getFormData(newSection.fields, {
  //       values: config.initialValues || {},
  //       validations: initialValidations,
  //     });
  //     setFormData(newFormData);
  //   }
  // }, [config, sections, activeIndex]);

  const changeHandler: TFieldChangeHandler = (
    field: TFormField,
    newValue: any
  ) => {
    // console.log(`Field "${field.name}" has new value ${JSON.stringify(newValue)}`);
    let newValidations = { ...formData.validations };
    const newValues = { ...formData.values, [field.name]: newValue };
    const validation = validateField(field, newValue, newValues);
    newValidations[field.name] = validation;
    checkDependents(field, section!.fields, newValues, newValidations,config);
    const newFormData: TFormData = {
      values: { ...formData.values, ...newValues },
      validations: { ...formData.validations, ...newValidations },
    };
    setFormData(newFormData);
  };

  const visibleFields = section?.fields.filter((f) => !f.hidden) || [];
  let allValid = true;
  for (let f of visibleFields) {
    if (!formData.validations[f.name]) {
      allValid = false;
      break;
    }
  }

  const handleSubmit = () => {
    if (nextSection) {
      // Clean up form data. Right now this just means removing string whitespace.
      const cleanValues: IStringIndex<any> = {};
      for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
        if (typeof fieldValue === "string") {
          cleanValues[fieldName] = fieldValue.replace(/\s+/g, " ").trim();
        } else {
          cleanValues[fieldName] = fieldValue;
        }
      }

      const nextData: TFormData = getFormData(
        nextSection.fields,
        {
          ...formData,
          values: cleanValues,
        },
        "EDIT",config
      );
      setFormData(nextData);
      setActiveIndex(activeIndex + 1);
    }
  };

  const saveSection = (newSection: TJourneySection) => {
    const newSections = [...sections];
    newSections[activeIndex] = newSection;
    setConfig({ ...config, sections: newSections });
  };

  const saveField = (props: { new?: TFormField; old?: TFormField }) => {
    const oldFieldIndex = section!.fields.findIndex(
      (f) => f.name === props.old?.name
    );
    if (oldFieldIndex >= 0) {
      const newFields = [...section!.fields];
      if (props.new) {
        newFields[oldFieldIndex] = props.new;
      } else {
        newFields.splice(oldFieldIndex, 1);
      }
      const newSection = { ...section, fields: newFields };
      const newSections = [...config.sections];
      newSections[activeIndex] = { ...newSection } as TJourneySection;
      const newConfig = { ...config, sections: newSections };
      setConfig(newConfig);
    } else if (props.new) {
      const newFields = [...section!.fields];
      newFields.push(props.new);
      const newSection = { ...section, fields: newFields };
      const newSections = [...config.sections];
      newSections[activeIndex] = { ...newSection } as TJourneySection;
      const newConfig = { ...config, sections: newSections };
      setConfig(newConfig);
    }
  };

  const addField = () => {
    saveField({
      new: {
        name: "unnamed field",
        type: "text",
        required: true,
      },
    });
  };

  const renderSectionIntro = () => {
    if (!section) {
      return null;
    }
    if (editing) {
      return (
        <SectionDetailsEditor
          originalSection={{ ...section }}
          onSave={saveSection}
        />
      );
    }
    return (
      <>
        {section.heading && (
          <h1>{substituteForHeadings(section.heading, formData.values)}</h1>
        )}
        {section.description && <p>{section.description}</p>}
      </>
    );
  };

  const renderNavigationButtons = () => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <IconButton
          color="primary"
          component="span"
          onClick={handleBack}
          disabled={activeIndex === 0}
        >
          <ArrowBackIcon />
        </IconButton>
        <h4>{`Section ${activeIndex + 1} / ${sections.length} `}</h4>
        <IconButton
          color="primary"
          component="span"
          onClick={handleForward}
          disabled={activeIndex === sections.length - 1}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Stack>
    );
  };

  const saveProduct = (product: TJourneyProduct) => {
    const newConfig = { ...config, product };
    setConfig(newConfig);
  };

  const saveAPIs = (APIs: any) => {
    const newConfig = { ...config, APIs };
    setConfig(newConfig);
  };

  const saveInfo = (info: TJourneyInfo) => {
    const newConfig = { ...config, info };
    setConfig(newConfig);
  };

  const saveSections = (sections: TJourneySection[]) => {
    setConfig({ ...config, sections });
  };

  return (
    <EditorContext.Provider value={{ saveField, config, formData }}>
      <JourneyContext.Provider
        value={{ formData, setFormData, mode: "DEFAULT" }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "white",
            borderRadius: 4,
            padding: 2,
            marginTop: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <TopButtons
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
            <Button
              color="success"
              size="large"
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={() =>
                props.onSave(
                  JSON.stringify(
                    { ...config, lastModified: new Date() },
                    null,
                    2
                  )
                )
              }
            >
              Save
            </Button>
          </Stack>
          {editing && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              <ProductEditor
                originalProduct={config.product || {}}
                onSave={saveProduct}
              />
              <InfoEditor originalInfo={config.info || {}} onSave={saveInfo} />
              <APIsEditor originalAPIs={config.APIs || {}} onSave={saveAPIs} />
              <ReferralsEditor
                originalReferrals={config.referrals || {}}
                onSave={saveAPIs}
              />
              <SectionsManager
                onSave={saveSections}
                originalSections={config.sections}
                setActiveIndex={setActiveIndex}
              />
            </Stack>
          )}
        </Container>
        <Container
          maxWidth="sm"
          className="journeyContainer"
          sx={{ marginTop: 2 }}
        >
          <Stack spacing={2}>
            {section && (
              <>
                {renderNavigationButtons()}
                {renderSectionIntro()}
                {editing && <Divider>Edit Section Fields</Divider>}
                {section.fields &&
                  renderFields(
                    config,
                    section.fields,
                    formData,
                    changeHandler,
                    editing,
                    editing ? "EDIT" : "DEFAULT"
                  )}
                {editing && (
                  <div>
                    <Button
                      variant="outlined"
                      startIcon={<AddCircleIcon />}
                      onClick={addField}
                    >
                      Add Field
                    </Button>
                  </div>
                )}
              </>
            )}
          </Stack>
        </Container>
        {section && (
          <SubmitButton
            key={section.name}
            onSubmit={handleSubmit}
            label={section.submitLabel || "Submit"}
            disabled={!allValid || editing}
          />
        )}
        {<DebugView config={config} formData={formData} />}
      </JourneyContext.Provider>
    </EditorContext.Provider>
  );
};
