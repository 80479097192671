import { useContext } from "react";
import { JourneyContext } from "../../JourneyContext";
import { Alert } from "@mui/material";
import { TFormData, TFormField } from "../../types";
import { replacePlaceholders } from "../../formHandling";

// eslint-disable-next-line no-template-curly-in-string
const servicePhoneString = "${SERVICE_PHONE}";

export const Blocker = (props: { field: TFormField; formData: TFormData }) => {
  const journeyContext = useContext(JourneyContext);

  const title = props.field.title;
  const servicePhoneNumber = journeyContext?.branding?.servicing_phone;

  if (title?.includes(servicePhoneString)) {
    const splittedTitle = title.split(servicePhoneString);

    if (splittedTitle.length === 2) {
      return (
        <p>
          {splittedTitle[0]}
          <a
            href={`tel:${servicePhoneNumber}`}
            style={{ color: "rgb(0,0,250)" }}
          >
            {servicePhoneNumber}
          </a>
          {splittedTitle[1]}
        </p>
      );
    }
  }
  if (props.field.variant) {
    return (
      <Alert
        sx={{ fontWeight: "600" }}
        variant="standard"
        severity={(props.field.variant as any) ?? "info"}
      >
        {replacePlaceholders(props.formData.values, props.field.title ?? "")}
      </Alert>
    );
  }
  return <b>{props.field.title}</b>;
};