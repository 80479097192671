import { Button, Divider, Stack } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useRef, useState } from "react";

export const FileChooser = (props?: {
  successHandler: (obj: any) => void;
  failureHandler: (error: any) => void;
}) => {
  const [dragHighlight, setDragHighlight] = useState(false);

  const readJSONFile = (f: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        try {
          const parsed = JSON.parse(event.target.result as string);
          props?.successHandler && props?.successHandler(parsed);
        } catch (e) {
          props?.failureHandler && props?.failureHandler(e);
        }
      }
    };
    reader.readAsText(f);
  };

  const inputRef = useRef<null | HTMLInputElement>(null);

  const chooseFile = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length === 1) {
      readJSONFile(files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragHighlight(false);
    if (event.dataTransfer?.files?.length === 1) {
      readJSONFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (!dragHighlight) {
      setDragHighlight(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLElement>) => {
    if (dragHighlight) {
      setDragHighlight(false);
    }
  };

  return (
    <Stack
      sx={{
        border: "1px solid rgba(0,0,0,0.5)",
        padding: 3,
        borderRadius: 4,
      }}
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          border: dragHighlight ? "2px dashed royalblue" : "2px dashed rgba(0,0,0,0.5)",
          padding: 3,
          borderRadius: 3,
          position: "relative",
          backgroundColor: dragHighlight ? "#EEEEEE" : "transparent",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: 8,
            zIndex: 1,
          }}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        ></div>
        <UploadFileIcon fontSize="large" color={dragHighlight ? "success" : "primary"} />
        <p>Drag a JSON file here to start editing</p>
      </Stack>
      <Divider sx={{ width: "100%" }}>OR</Divider>
      <Button variant="contained" onClick={chooseFile}>
        Choose a file
      </Button>
      <input
        type="file"
        style={{ display: "none" }}
        id="fileInput"
        ref={inputRef}
        accept=".json"
        onChange={handleFileSelect}
      ></input>
    </Stack>
  );
};
