// No product ID
import FieldTester from './no-id/Field Tester.json';
import ProductTester from './no-id/Product Tester.json';
// Updated product IDs
import ERS from './pro-75791180-70b9-4ec7-8bb5-d20a69787d69/ERS.json';
import GuaranteedLife from './pro-87b397be-396b-4f36-9590-873548e94f5e/GuaranteedLife.json';
import AOIP from './pro-9dc2ce4a-73a8-4f32-a55f-df022f1275f6/AOIP.json';
import TermLife from './pro-af131b02-5d32-45be-ab7f-721199896c69/TermLife.json';
import TyreAndAlloy from './pro-79219268-f1cf-47a5-9203-7714fabe1ff4/TyreAndAlloy.json';
import ScratchAndDentCommercial from './pro-7008391d-8872-4bba-96cc-9accf0de0afc/ScratchAndDentTaxiDI.json';
import ScratchAndDentPersonalOneEdge from './pro-e81ce45f-6909-485f-81d7-f7935065446d/ScratchAndDentPersonalOneEdge.json';

import ScratchAndDentPersonal from './pro-514586b8-09ab-44c5-bcf6-cf80d20f6786/ScratchAndDentPersonal.json';
import AcastaCycle from './pro-db68ff14-08b8-4403-b00f-df48d1ffd967/AcastaCycle.json';
import MotorBreakdownPersonal from './pro-8b80a9a2-84fb-43f3-84a3-8ecb96ff7de3/MotorBreakdownPersonal.json';
import MotorBreakdownCommercial from './pro-85aabeb8-93b6-4d34-9768-679c103e1801/MotorBreakdownTaxiDi.json';
import MultipleAppliance from './pro-4521c2d5-16ed-4850-8cf2-05362c65ebd2/MultipleAppliance.json';
import GAPPersonal from './pro-64111e4e-9245-45fe-a942-ebf94479c94d/GAPPersonal.json';
import GAPHRDT from './pro-c0bc13e4-b1db-4f55-a3e7-ce33f2696a57/GAPHRDT.json';
import HomeEmergency from './pro-4bcedf3b-1635-4d57-83d0-c2746ddc4ea8/HomeEmergency.json';
import HomeEmergencyLandlord from './pro-df4d7887-f446-4ba6-a0d7-530cfb3fc149/HomeEmergencyLandlord.json';
import SideBySide from './pro-3664dba3-50d5-431f-9798-d181d0a7ad99/SideBySide.json';
import CedarCPO from './pro-99f162de-bd6f-4577-8123-e0d2d27c99ed/CEDAR-CPO.json';
import oneEdgeGuaranteedOverFifty from './pro-8a330b03-295d-430a-8bdc-6da39e54f063/oneEdgeGuaranteedOverFifty.json';
import { TJourneyConfig } from '../types';

export const allJourneyConfigs = [
  // ProductTester,
  // FieldTester,
  // ERS,
  AOIP,
  // CedarCPO,
  GAPPersonal,
  GAPHRDT,
  MotorBreakdownPersonal,
  MotorBreakdownCommercial,
  GuaranteedLife,
  HomeEmergency,
  HomeEmergencyLandlord,
  MultipleAppliance,
  SideBySide,
  TermLife,
  TyreAndAlloy,
  ScratchAndDentPersonal,
  ScratchAndDentCommercial,
  ScratchAndDentPersonalOneEdge,
  AcastaCycle,
  // oneEdgeGuaranteedOverFifty,
] as TJourneyConfig[];

export const getJourneyById = (productId: string) => {
  const match = allJourneyConfigs.find((j) => j.product.id === productId);
  return match ?? null;
};
