import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const EditorDialog = (props: {
  children: any;
  isOpen: boolean;
  onApply?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
}) => {
  const { children, isOpen, onApply, onCancel, onDelete } = props;
  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      fullWidth
      PaperProps={{ sx: { width: "100%", margin: "0px", borderRadius: 4, padding: 1 } }}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onApply && (
          <Button
            startIcon={<CheckCircleIcon />}
            onClick={onApply}
            color="success"
            variant="contained"
          >
            Apply
          </Button>
        )}
        {onCancel && (
          <Button startIcon={<CancelIcon />} onClick={onCancel} variant="contained">
            Cancel
          </Button>
        )}
        {onDelete && (
          <Button
            startIcon={<DeleteForeverIcon />}
            onClick={onDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
