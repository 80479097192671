// =================================================================================================
// Form field dynamic component rendering
// =================================================================================================

import { initDependencies } from '../../formHandling';
import {
  TFormField,
  TFieldChangeHandler,
  TFormData,
  IStringIndex,
  TJourneyConfig,
  TJourneyMode,
} from '../../types';
import { FieldBase } from './FieldBase';
import { TextField } from './TextField';
import { SelectField } from './SelectField';
import { SliderField } from './SliderField';
import { OptionField } from './OptionField';
import { NumberField } from './NumberField';
import { InfoField } from './InfoField';
import { SummaryField } from './SummaryField';
import { SwitchField } from './SwitchField';
import { SwitchInfoField } from './SwitchInfo';
import { AutocompleteField } from './AutocompleteField';
import { DateField } from './DateField';
import { AddressField } from './AddressField';
import { MeasurementsField } from './Measurements/MeasurementsField';
import { createElement, ReactElement } from 'react';
import { VehicleField } from './Vehicle/VehicleField';
import { Blocker } from './Blocker';
import { MessageField } from './MessageField';
import { HTMLField } from './HTMLField';
import { AppliancesField } from './AppliancesField';
import { PremiumSumAssuredField } from './PremiumSumAssuredField';
import { ArrayField } from './Array/ArrayField';
import { DirectDebitField } from './DirectDebit';
import { Setter } from './Setter';
import { LicenceField } from './LicenceField';
import { VehicleDriverAssignments } from './VehicleDriverAssignments';
import { VehicleERS } from './VehicleERS/VehicleERS';
import { ModificationsField } from './ModificationsField';
import { CurrencyField } from './CurrencyField';
// import { PremiumSumField } from "./PremiumSumField";
import { AccordionField } from './AccordionField';
import DirectDebitGuarantee from './DirectDebitGuarantee';
import { MessageNotificationField } from './MessageNotificationField';

export const components: IStringIndex<any> = {
  text: TextField,
  select: SelectField,
  slider: SliderField,
  option: OptionField,
  number: NumberField,
  info: InfoField,
  summary: SummaryField,
  switch: SwitchField,
  switchInfo: SwitchInfoField,
  autocomplete: AutocompleteField,
  date: DateField,
  address: AddressField,
  measurements: MeasurementsField,
  vehicle: VehicleField,
  blocker: Blocker,
  message: MessageField,
  html: HTMLField,
  appliances: AppliancesField,
  premiumSumAssured: PremiumSumAssuredField,
  array: ArrayField,
  directDebit: DirectDebitField,
  directDebitGuarantee: DirectDebitGuarantee,
  setter: Setter,
  licence: LicenceField,
  vehicleDriverAssignments: VehicleDriverAssignments,
  vehicleERS: VehicleERS,
  modifications: ModificationsField,
  currency: CurrencyField,
  accordion: AccordionField,
  notification: MessageNotificationField,
};

export const renderFields = (
  config: TJourneyConfig,
  fields: Array<TFormField>,
  formData: TFormData,
  changeHandler: TFieldChangeHandler,
  disabled = false,
  mode: TJourneyMode
) => {
  const elements: ReactElement[] = [];

  // console.log("Render fields", JSON.stringify(fields, null, 2));
  // console.log("Form data", JSON.stringify(formData, null, 2));

  for (let f of fields) {
    if (f.hidden && mode !== 'EDIT') {
      continue;
    }
    if (components[f.type] !== undefined) {
      const fieldData = {
        value: formData.values[f.name],
        validation: formData.validations[f.name],
      };

      const fieldIsDisabled = disabled || (f.disabledForMTA === true && mode === 'MTA');

      if (f.type === 'setter') {
        elements.push(<Setter key={f.name} field={{ ...f }} />);
        continue;
      }

      // "Special" fields that need more parameters
      if (f.type === 'summary') {
        elements.push(
          <FieldBase key={f.name} mode={mode} field={f} config={config} formData={formData}>
            <SummaryField
              field={{ ...f, disabled: f.autoClear ? false : fieldIsDisabled }}
              formData={formData}
              config={config}
              changeHandler={changeHandler}
            />
          </FieldBase>
        );
        continue;
      }
      if (f.type === 'array') {
        initDependencies(f.fields ?? [], 'DEFAULT');
        elements.push(
          <FieldBase key={f.name} mode={mode} field={f} config={config} formData={formData}>
            <ArrayField
              field={{ ...f, disabled: f.autoClear ? false : fieldIsDisabled }}
              formData={formData}
              config={config}
              value={fieldData.value}
              changeHandler={changeHandler}
            />
          </FieldBase>
        );
        continue;
      }
      if (f.type === 'info') {
        elements.push(
          <FieldBase key={f.name} mode={mode} field={f} config={config} formData={formData}>
            <InfoField
              field={{ ...f, disabled: f.autoClear ? false : fieldIsDisabled }}
              config={config}
            />
          </FieldBase>
        );
        continue;
      }

      if (f.type === 'switchInfo') {
        elements.push(
          <FieldBase key={f.name} mode={mode} field={f} config={config} formData={formData}>
            <SwitchInfoField
              field={{ ...f, disabled: f.autoClear ? false : fieldIsDisabled }}
              value={fieldData.value}
              config={config}
              changeHandler={changeHandler}
            />
          </FieldBase>
        );
        continue;
      }

      // "Regular" fields
      elements.push(
        <FieldBase key={f.name} mode={mode} field={f} config={config} formData={formData}>
          {f?.subtitle?.type === 'html' && <HTMLField field={f.subtitle} />}
          {createElement(components[f.type], {
            field: { ...f, disabled: f.autoClear ? false : fieldIsDisabled },
            value: fieldData.value,
            validation: fieldData.validation,
            formData,
            changeHandler,
            config,
          })}
        </FieldBase>
      );
    } else {
      elements.push(
        <FieldBase key={f.name} field={f} config={config} formData={formData}>
          <pre>{JSON.stringify(f, null, 2)}</pre>
        </FieldBase>
      );
    }

    if (f.type === 'blocker') {
      break;
    }
  }
  return elements;
};
