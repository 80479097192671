import { Button, Container, Grid, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useState } from "react";
import { TBranding, TJourneyConfig, TJourneyMode, TProductData } from "../types";
import { Branding } from "./Branding";
import { LoadingIndicator } from "./LoadingIndicator";
import { ProductsContext } from "../ProductsApiContext";
import { getJourneyById } from "../journeyConfigs";

export type TJourneyPickerProps = {
  mode: TJourneyMode;
  journeys: TProductData[];
  branding?: TBranding;
  setActiveJourney: (journey: TJourneyConfig) => void;
};

export const JourneyPicker = (props: TJourneyPickerProps) => {
  const { journeys, mode, setActiveJourney } = props;
  const matches = useMediaQuery("(min-width:1200px)");
  const [isLoading, setIsLoading] = useState(false);
  const { updateProductData } = useContext(ProductsContext);

  const openJourney = async (obj: TProductData) => {
    if (mode === "TEST") {
      setActiveJourney(obj);
      return;
    } else {
      setIsLoading(true);
      const journeyConfig = getJourneyById(obj.id);
      setActiveJourney({
        ...journeyConfig,
        product: {
          ...journeyConfig?.product,
          title: obj.productName ?? journeyConfig?.product.title,
        },
      } as TJourneyConfig);
      updateProductData(obj);
      setIsLoading(false);
    }
  };

  const renderShopfrontText = () => {
    return (
      <>
        <h1 className="shopfront">Online products</h1>
        <h4>Insurance made simple, just for you.</h4>
        <p>
          Demystify the insurance process! We’re committed to providing you with the best insurance
          options for your specific needs. Get covered effortlessly today using our easy-to-use
          platform.
        </p>
        <p>Browse our comprehensive covers - instant quotes are just a few clicks away.</p>
      </>
    );
  };

  const renderJourneyCards = () => {
    return (
      <Grid container rowSpacing={3} spacing={3}>
        {journeys.map((journey) => (
          <Grid
            key={journey.productName ?? journey.product.title}
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {renderJourneyCard(journey)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderJourneyCard = (journey: TProductData) => {
    // in test mode the data comes from local configs
    const getImagePath =
      mode === "TEST"
        ? `url(img/products/${journey.product.image ?? "placeholder.png"})`
        : `url(${journey.images.full ?? "placeholder.png"})`;

    return (
      <Stack className="card" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack width={"100%"} spacing={2}>
          <div
            className="cardImage"
            style={{
              backgroundImage: getImagePath,
              backgroundSize: "cover",
            }}
          ></div>
          <h3 className="cardHeader" style={{ whiteSpace: "pre-wrap" }}>
            {journey.productName ?? journey.product.title}
          </h3>
          {journey.subtitle && <small className="cardSubtitle">{journey.subtitle}</small>}
        </Stack>
        <p className="cardText">{journey.description ?? journey.product.description}</p>
        <Button
          variant="contained"
          className="cardButton"
          onClick={() => openJourney(journey)}
          sx={{ textTransform: "none", fontFamily: "Poppins" }}
        >
          Buy now
        </Button>
      </Stack>
    );
  };

  let count = 0;
  return matches ? (
    <div style={{ width: "100%" }}>
      {props.branding && <Branding branding={props.branding} />}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Container maxWidth="xl">
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
              <div style={{ flex: 1, padding: 20 }}>
                <Stack spacing={3}>{renderShopfrontText()}</Stack>
              </div>
              <div style={{ flex: 3, padding: 32 }}>{renderJourneyCards()}</div>
            </div>
          </Container>
          {journeys.length === 0 && (
            <Container maxWidth="sm" sx={{ backgroundColor: "F4F5FA", marginTop: 4 }}>
              <h3>There are no products available.</h3>
            </Container>
          )}
        </>
      )}
    </div>
  ) : (
    <>
      {props.branding && <Branding branding={props.branding} />}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Container maxWidth="sm" sx={{ marginTop: 4 }}>
            <Stack spacing={3}>{renderShopfrontText()}</Stack>
          </Container>
          {journeys.length > 0 && (
            <Container maxWidth="md" sx={{ marginTop: 8, marginBottom: 8 }}>
              {renderJourneyCards()}
            </Container>
          )}
          {journeys.length === 0 && (
            <Container maxWidth="sm" sx={{ backgroundColor: "F4F5FA", marginTop: 4 }}>
              <h3>There are no products available.</h3>
            </Container>
          )}
        </>
      )}
    </>
  );
};
