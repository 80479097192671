import {
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useContext, useEffect } from "react";
import { JourneyContext } from "../JourneyContext";
import { useState } from "react";
import { IStringIndex, TJourneyConfig, TJourneySection } from "../types";
import { EmbeddedJourneySection } from "./EmbeddedJourneySection";
import { QuoteHeader } from "./QuoteHeader";
import moment from "moment";
import { activeJourneyContext } from "../activeJourneyContext";

export const JourneyHeader = (props: {
  index: number;
  config?: TJourneyConfig;
  section?: TJourneySection;
  formValues?: IStringIndex<any>;
  disabled?: boolean;
}) => {
  const activeJourney = useContext(activeJourneyContext);
  const isScratchAndDent1Edge =
    activeJourney?.product.id === "pro-e81ce45f-6909-485f-81d7-f7935065446d";

  const [dialogOpen, setDialogOpen] = useState(false);

  const { config, section, formValues, disabled, index } = props;

  const paymentSection = section?.isPayment === true;
  const paymentHeaderDisabled = paymentSection && formValues?.paymentIntent?.status === "requires_payment_method"

  const headerSection = config?.sections
    ? config?.sections.find(
        (section) => section.name === config.product.headerSection
      )
    : undefined;

  const quoteIndex = config?.sections
    ? config.sections.findIndex((s) => s.isQuote === true)
    : -1;

  const premium = Number(
    formValues?.MTA?.premium || formValues?.quote?.premium
  );
  const monthlyPremium = Number(
    formValues?.MTA?.monthlyPremium || formValues?.quote?.monthlyPremium
  );

  const currentDate = moment().format("DD/MM/YYYY");

  const paymentFrequency = formValues?.paymentFrequency || "MONTHLY";

  const stripeCollects = formValues?.MTA?.stripeCollects;
  const renderHeaderContent = () => {
    if (!config) {
      return <h4>Choose a Product</h4>;
    }
    if (section?.isQuote || section?.isFinal) {
            return (
              <QuoteHeader
          config={config}
          IPT={config.product.includesIPT ?? false}
          quote={formValues!.quote}
          MTA={formValues!.MTA}
          paymentFrequency={paymentFrequency}
        />          
      );
    }

    // This is terrible, but it will have to do for now
    const yearlyText =
      config.product.id === "pro-af131b02-5d32-45be-ab7f-721199896c69" ||
      config.product.id === "pro-87b397be-396b-4f36-9590-873548e94f5e"
        ? "yearly"
        : "one-off";
    return (
      <>
        <Typography
          id="productTitle"
          variant="h1"
          sx={{ color: "primary.contrastText", whiteSpace: "pre-wrap" }}
        >
          {config.product.title}
        </Typography>
        {config.product.subtitle && (
          <small className="headerSubtitle">{config.product.subtitle}</small>
        )}
        {premium !== undefined && index > quoteIndex && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography
                variant="h3"
                id="paymentText"
                sx={{ color: "primary.contrastText" }}
              >
                {paymentFrequency === "MONTHLY" && (
                  <div>
                    <p>
                      {config?.payment?.provider === "stripe" &&
                      !formValues?.MTA
                        ? `Deposit £${
                            formValues?.quote?.deposit
                          } followed by 1 installment of £${
                            formValues?.quote?.pcQuote?.firstCollectionAmount
                          } and ${
                            formValues?.quote?.pcQuote?.numberOfCollections - 1
                          } monthly installments of £${
                            formValues?.quote?.pcQuote?.recurringPayment
                          }`
                        : "Your monthly payment is £" + monthlyPremium}
                    </p>
                  </div>
                )}
                {paymentFrequency.toUpperCase() !== "MONTHLY" && (
                  <div>
                    <p>
                      Your {yearlyText} payment is £{premium}
                    </p>
                  </div>
                )}
              </Typography>

              {headerSection && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ color: "primary.contrastText" }}
                  >
                    <span
                      style={{ cursor: disabled || paymentHeaderDisabled ? "default" : "pointer" }}
                      onClick={() => {                     
                        if (!disabled || !paymentHeaderDisabled) {
                          setDialogOpen(true);
                        }
                      }}
                    >
                      Amend details
                    </span>
                    <IconButton
                      onClick={() => setDialogOpen(true)}
                      disabled={disabled || paymentHeaderDisabled }
                    >
                      <EditIcon sx={{ color: "primary.contrastText" }} />
                    </IconButton>
                  </Stack>
                  <Dialog
                    open={dialogOpen}
                    scroll="paper"
                    fullWidth
                    PaperProps={{
                      sx: { width: "100%", margin: "0px", maxHeight: "100%" },
                    }}
                  >
                    <DialogContent className="dialogContent">                   
                      <EmbeddedJourneySection
                        section={headerSection}
                        config={config}
                        onCancel={() => setDialogOpen(false)}
                        onSuccess={() => setDialogOpen(false)}
                        activeIndex={index}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </Stack>
            <Box sx={{ color: "primary.contrastText" }}>
              {isScratchAndDent1Edge && (
                <p style={{ fontSize: 14, fontWeight: "normal" }}>
                  Price valid as of {currentDate}
                </p>
              )}
              {isScratchAndDent1Edge && (
                <p style={{ fontSize: 14, fontWeight: "normal" }}>
                  All premiums are inclusive of Insurance Premium Tax (IPT)
                  which is 12%
                </p>
              )}
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <Box
      id="wljHeader"
      sx={{
        backgroundColor: "primary.main",
      }}
    >
      <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
        {renderHeaderContent()}
      </Container>
    </Box>
  );
};
