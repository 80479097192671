import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";

const DirectDebitGuarantee = () => {
  return (
    <Card sx={{border:"3px solid black"}}>
      <CardContent>
        <Typography fontWeight={600} align="center" variant="h5" component="h2">
          Direct Debit Guarantee
        </Typography>
        <ul style={{fontWeight:600, fontSize: "12px", padding: 20,textAlign:"justify" ,display:"flex", flexDirection:"column", gap:8}}>
          <li>
            The Guarantee is offered by all banks and building societies that accept instructions to
            pay Direct Debits.
          </li>
          <li>
            If there are any changes to the amount, date, or frequency of your Direct Debit Premium
            Credit will notify you (normally 10 working days) in advance of your account being
            debited or as otherwise agreed. If you request Premium Credit to collect a payment,
            confirmation of the amount and date will be given to you at the time of the request.
          </li>
          <li>
            If an error is made in the payment of your Direct Debit, by Premium Credit or your bank
            or building society, you are entitled to a full and immediate refund of the amount paid
            from your bank or building society.
            <p style={{marginLeft:15}}>
              - If you receive a refund, you are not entitled to, you must pay it back when Premium
              Credit asks you to.
            </p>
          </li>
          <li>
            You can cancel a Direct Debit at any time by simply contacting your bank or building
            society. Written confirmation may be required. Please also notify Premium Credit.
          </li>
          <li>
            In the future, if there is a change to the date, amount, or frequency of your Direct
            Debits, Premium Credit will always notify you five working days’ in advance of your
            account being debited. In the event of an error, you are entitled to request a refund
            from your bank or building society. You have the right to withdraw the credit agreement
            within the 14-day cooling off period. A copy of the safeguards under the Direct Debit
            Guarantee will be sent to you with your confirmation letter from Premium Credit.
          </li>
        </ul>

        <Stack alignItems={"center"}>
          <img src="/img/directdebitlogo.jpeg" alt="Direct Debit Logo" />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DirectDebitGuarantee;
