import { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { renderFields } from "../fields";

import {
  IStringIndex,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
} from "../../../types";
import { validateField } from "../../../fieldValidation";
import { checkDependents, initDependencies } from "../../../formHandling";
import { JourneyContext } from "../../../JourneyContext";

export const ArrayFieldDialog = (props: {
  config: TJourneyConfig;
  fields: TFormField[];
  value: any;
  title?: string;
  label?: string;
  onApply: (value: any) => void;
  onCancel: () => void;
  extras?: any;
  validations?: any;
}) => {
  const journeyContext = useContext(JourneyContext);
  const { title, label, value, config, fields, onCancel, onApply, extras } = props;

  const [localFormData, setLocalFormData] = useState<TFormData>({
    values: { ...value, ...extras },
    validations: { ...props.validations },
  });

  useEffect(() => {
    initDependencies(fields, "DEFAULT");
    const validations: IStringIndex<boolean> = {};
    for (let f of fields) {
      validations[f.name] = validateField(f, localFormData.values[f.name], localFormData);
      checkDependents(f, fields, localFormData.values, localFormData.validations);
    }
    setLocalFormData({ ...localFormData, validations });
  }, [fields]);

  const localChangeHandler: TFieldChangeHandler = (field: TFormField, value: any) => {
    const newFormData: TFormData = { ...localFormData };
    newFormData.values[field.name] = value;
    newFormData.validations[field.name] = validateField(field, value, newFormData.values);
    checkDependents(field, fields, newFormData.values, newFormData.validations);
    setLocalFormData(newFormData);
  };

  const handleApply = () => {
    onApply(localFormData);
  };

  const handleCancel = () => {
    onCancel();
  };

  let allValid = true;
  for (let f of fields) {
    if (f.required && localFormData.validations[f.name] !== true) {
      allValid = false;
      break;
    }
  }

  return (
    <Dialog
      open={true}
      scroll="paper"
      fullWidth
      PaperProps={{ sx: { width: "100%", margin: "0px", minHeight: "550px" } }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <br />
      <DialogContent className="dialogContent">
        <Stack spacing={3}>
          {label && <DialogContentText>{label}</DialogContentText>}
          {/* This right here illustrates how badly this whole show needs a rewrite */}
          <JourneyContext.Provider
            value={{
              ...journeyContext,
              formData: localFormData,
              setFormData: setLocalFormData,
            }}
          >
            {renderFields(config, fields, localFormData, localChangeHandler, false, "DEFAULT")}
          </JourneyContext.Provider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!allValid}
          startIcon={<CheckCircleIcon />}
          onClick={handleApply}
          color="success"
          variant="contained"
          className="dialogButton"
        >
          Save
        </Button>
        <Button
          startIcon={<CancelIcon />}
          onClick={handleCancel}
          variant="contained"
          className="dialogButton"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
