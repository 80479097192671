import { Stack, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { TJourneySection } from "../../types";
import { EditorCard } from "./EditorCard";
import { EditorDialog } from "./EditorDialog";

export const SectionDetailsEditor = (props: {
  originalSection: TJourneySection;
  onSave: (section: TJourneySection) => void;
}) => {
  const { originalSection, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editSection, setEditSection] = useState(originalSection);

  useEffect(() => {
    console.log("Section Details Props Changed");
    setEditSection(props.originalSection);
  }, [props]);

  const applyChanges = () => {
    onSave(editSection);
    closeDialog();
  };

  const discardChanges = () => {
    closeDialog();
    setEditSection(originalSection);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const renderInputs = () => {
    return (
      <Stack spacing={4}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Name"
          value={editSection.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditSection({ ...editSection, name: event.target.value })
          }
          helperText="The name of the section. Must be unique per config."
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Heading"
          value={editSection.heading}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditSection({ ...editSection, heading: event.target.value })
          }
          helperText="The heading displayed at the top of the section."
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Description"
          value={editSection.description}
          multiline={true}
          rows={3}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditSection({ ...editSection, description: event.target.value })
          }
          helperText="An optional text description displayed after the heading."
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Submit label"
          value={editSection.submitLabel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEditSection({ ...editSection, submitLabel: event.target.value })
          }
          helperText={`The submit button's label. Defaults to "Submit"`}
        />
        <div>
          <FormControlLabel
            control={<Checkbox checked={editSection.isQuote || false} />}
            label="Is quote section"
            onChange={(event, checked) => setEditSection({ ...editSection, isQuote: checked })}
          />
          <FormControlLabel
            control={<Checkbox checked={editSection.isFinal || false} />}
            label="Is final section"
            onChange={(event, checked) => setEditSection({ ...editSection, isFinal: checked })}
          />
        </div>
      </Stack>
    );
  };

  const renderValues = () => {
    return (
      <Stack spacing={1}>
        <p>
          <b style={{ width: 100 }}>Name</b>: {originalSection.name || "(unnamed)"}
        </p>
        <p>
          <b>Heading</b>: {originalSection.heading || "(no heading)"}
        </p>
        <p>
          <b>Description</b>: {originalSection.description || "(no description)"}
        </p>
        <p>
          <b>Submit label</b>: {originalSection.submitLabel || "(no submit label)"}
        </p>
        <p>
          <b>Is quote section</b>: {originalSection.isQuote ? "Yes" : "No"}
        </p>
        <p>
          <b>Is final section</b>: {originalSection.isFinal ? "Yes" : "No"}
        </p>
      </Stack>
    );
  };

  return (
    <>
      <EditorDialog isOpen={dialogOpen} onApply={applyChanges} onCancel={discardChanges}>
        {renderInputs()}
      </EditorDialog>
      <EditorCard onActivate={openDialog}>{renderValues()}</EditorCard>
    </>
  );
};
