import {
  Stack,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useContext, useState } from "react";
import { IStringIndex, TFormField } from "../../types";
import { EditorDialog } from "./EditorDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { EditorContext } from "./EditorContext";
import { PreviewJSON } from "./PreviewJSON";

export const ReferralsEditor = (props: {
  originalReferrals: IStringIndex<any>;
  onSave: (referrals: IStringIndex<any>) => void;
}) => {
  const { originalReferrals, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editReferrals, setEditReferrals] = useState(originalReferrals);
  const editorContext = useContext(EditorContext);

  const allOptionFields: TFormField[] = [];
  for (let section of editorContext.config.sections) {
    for (let f of section.fields) {
      // "Duplicate" fields may exist due to confirmation screens. Skip
      if (allOptionFields.find((opt) => opt.name === f.name)) {
        continue;
      }
      if (["option", "select", "autocomplete"].includes(f.type) && f.options?.length) {
        allOptionFields.push(f);
      }
    }
  }

  const referralsArray: string[] = [];
  for (let fieldName of Object.keys(editReferrals)) {
    referralsArray.push(fieldName);
  }

  const applyChanges = () => {
    onSave(editReferrals);
    closeDialog();
  };

  const discardChanges = () => {
    closeDialog();
    setEditReferrals(originalReferrals);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const renderFieldValues = (field: TFormField, value: any) => {
    if (!field.options) {
      return null;
    }
    const menuItems = [];
    for (let opt of field.options) {
      if (typeof opt === "object") {
        menuItems.push(
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        );
      } else if (typeof opt === "string") {
        menuItems.push(
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        );
      }
    }
    return menuItems;
  };

  const changeFieldName = (oldName: string, newName: string) => {
    const matchingField = allOptionFields.find((f) => f.name === newName);
    if (matchingField && matchingField.options && matchingField.options.length > 0) {
      const newValue =
        typeof matchingField.options[0] === "string"
          ? matchingField.options[0]
          : matchingField.options[0].value;
      // Do it this way to preserve order of keys
      let newReferrals = {};
      Object.keys(editReferrals).forEach((fieldName) => {
        if (fieldName === oldName) {
          newReferrals = { ...newReferrals, [newName]: newValue };
        } else {
          newReferrals = { ...newReferrals, [fieldName]: editReferrals[fieldName] };
        }
      });

      setEditReferrals(newReferrals);
    }
  };

  const changeFieldValue = (fieldName: string, newValue: string) => {
    const newReferrals = { ...editReferrals };
    newReferrals[fieldName] = newValue;
    setEditReferrals(newReferrals);
  };

  const deleteReferral = (fieldName: string) => {
    const newReferrals = { ...editReferrals };
    delete newReferrals[fieldName];
    setEditReferrals(newReferrals);
  };

  const addReferral = () => {
    // Find the first field that is not already used
    const firstUnused = allOptionFields.find((f) => !referralsArray.includes(f.name));
    if (firstUnused && firstUnused.options && firstUnused.options.length) {
      const newReferrals = { ...editReferrals };
      const newValue =
        typeof firstUnused.options[0] === "string"
          ? firstUnused.options[0]
          : firstUnused.options[0].value;
      newReferrals[firstUnused.name] = newValue;
      setEditReferrals(newReferrals);
    } else {
      alert("All possible option fields are already used.");
    }
  };

  const renderInputs = () => {
    const elements = [];
    let index = 0;
    for (let [fieldName, fieldValue] of Object.entries(editReferrals)) {
      const matchingField = allOptionFields.find((f) => f.name === fieldName);
      if (!matchingField) {
        continue;
      }
      elements.push(
        <Stack
          key={fieldName}
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
        >
          <p style={{ width: 80 }}>[{index}]</p>
          <FormControl fullWidth>
            <InputLabel id="label">Field Name</InputLabel>
            <Select
              labelId="label"
              value={fieldName || ""}
              label="Field Name"
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              size="medium"
              onChange={(event) => changeFieldName(fieldName, event.target.value)}
            >
              {allOptionFields.map((field, index) => (
                <MenuItem
                  key={field.name}
                  value={field.name}
                  disabled={referralsArray.includes(field.name)}
                >
                  {field.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="label2">Field Value</InputLabel>
            <Select
              labelId="label2"
              value={fieldValue || ""}
              label="Field Value"
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              size="medium"
              onChange={(event) => changeFieldValue(fieldName, event.target.value)}
            >
              {renderFieldValues(matchingField, fieldValue)}
            </Select>
          </FormControl>
          <IconButton color="error" onClick={() => deleteReferral(fieldName)}>
            <DeleteForeverIcon />
          </IconButton>
        </Stack>
      );
      index++;
    }
    return (
      <Stack spacing={4}>
        {elements}
        <div>
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={addReferral}
            disabled={
              allOptionFields.length === 0 || allOptionFields.length === referralsArray.length
            }
          >
            Add Referral
          </Button>
        </div>
        <PreviewJSON obj={editReferrals} />
      </Stack>
    );
  };

  return (
    <div>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button variant="contained" size="small" onClick={openDialog}>
        Referrals
      </Button>
    </div>
  );
};
