// =================================================================================================
// A component for entering a person's measurements in metric or imperial. Not my best work.
// =================================================================================================

import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { TFieldChangeHandler, TFormField } from "../../../types";

import convert from "convert";
import validator from "validator";
import { MetricHeight } from "./MetricHeight";
import { MetricWeight } from "./MetricWeight";
import { ImperialHeight } from "./ImperialHeight";
import { ImperialWeight } from "./ImperialWeight";

// MEASUREMENTS
export const MAX_MEASUREMENTS = {
  Metric: {
    CM: 350,
    KG: 250,
  },
  Imperial: {
    FEET: 11,
    INCHES: 6.1,
    STONE: 39,
    POUNDS: 7.4,
  },
};

// =================================================================================================
// Main component
// =================================================================================================

export const MeasurementsField = (props: {
  field: TFormField;
  value: { weight: number; height: number };
  changeHandler: TFieldChangeHandler;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------
  const { field, value, changeHandler } = props;
  const [metric, setMetric] = useState<boolean | undefined>();

  // Sanity
  const [cmError, setCmError] = useState("");
  const [kgError, setKgError] = useState("");

  // Lunacy
  const [feetError, setFeetError] = useState("");
  const [inchesError, setInchesError] = useState("");
  const [stoneError, setStoneError] = useState("");
  const [poundsError, setPoundsError] = useState("");

  // -----------------------------------------------------------------------------------------------
  // Change handlers
  // -----------------------------------------------------------------------------------------------

  const metricHeightChanged = (cm: string) => {
    if (Number(cm) > MAX_MEASUREMENTS.Metric.CM) {
      setCmError("Must be " + MAX_MEASUREMENTS.Metric.CM + "cm or less");
      if (value.height !== undefined) {
        changeHandler(field, { ...value, height: undefined });
      }
    } else if (validator.isDecimal(cm || "0") && Number(cm) >= 0) {
      //const newCm = Number(cm);
      setCmError("");
      changeHandler(field, { ...value, height: cm });
    } else {
      if (cmError === "") {
        setCmError("Must be a decimal number.");
      }
      if (value.height !== undefined) {
        changeHandler(field, { ...value, height: undefined });
      }
    }
  };

  const metricWeightChanged = (kg: string) => {
    if (Number(kg) > MAX_MEASUREMENTS.Metric.KG) {
      setKgError("Must be " + MAX_MEASUREMENTS.Metric.KG + "kg or less");
      if (value.weight !== undefined) {
        changeHandler(field, { ...value, weight: undefined });
      }
    } else if (validator.isDecimal(kg || "0") && Number(kg) >= 0) {
      //const newKg = Number(kg);
      setKgError("");
      changeHandler(field, { ...value, weight: kg });
    } else {
      if (kgError === "") {
        setKgError("Must be a decimal number.");
      }
      if (value.weight !== undefined) {
        changeHandler(field, { ...value, weight: undefined });
      }
    }
  };

  const imperialHeightChanged = (feet: string, inches: string) => {
    const validFeet = validator.isInt(feet || "0") && Number(feet) >= 0;
    const validInches =
      validator.isDecimal(inches || "0") && Number(inches) >= 0;
    if (validFeet && validInches) {
      const newInches = Number(feet) * 12 + Number(inches);
      const newCm = convert(newInches, "inches").to("cm");
      // console.log(newCm);
      // checking if overall imperial height is within range
      if (Math.trunc(newCm) > MAX_MEASUREMENTS.Metric.CM) {
        // triggering both field errors to show main error
        setFeetError(" ");
        setInchesError(" ");

        changeHandler(field, { ...value, height: undefined });
        return;
      }
      if (Number(feet) > MAX_MEASUREMENTS.Imperial.FEET) {
        setFeetError(
          "Feet must be between 0 and " + MAX_MEASUREMENTS.Imperial.FEET
        );
        changeHandler(field, { ...value, height: undefined });
        return;
      }
      changeHandler(field, { ...value, height: newCm + "" });
    } else {
      changeHandler(field, { ...value, height: undefined });
    }
    if (!validFeet) {
      setFeetError("Must be an integer number.");
    } else {
      setFeetError("");
    }
    if (!validInches) {
      setInchesError("Must be a decimal number.");
    } else {
      setInchesError("");
    }
  };

  const imperialWeightChanged = (stone: string, pounds: string) => {
    const validStone = validator.isInt(stone || "0") && Number(stone) >= 0;
    const validPounds =
      validator.isDecimal(pounds || "0") && Number(pounds) >= 0;
    if (validStone && validPounds) {
      if (Number(pounds) >= 14) {
        setPoundsError("Pounds must be between 0 and 13");
        changeHandler(field, { ...value, height: undefined });
        return;
      }
      const newPounds = Number(stone) * 14 + Number(pounds);
      const newKg = convert(newPounds, "pounds").to("kg");
      if (Math.trunc(newKg) > MAX_MEASUREMENTS.Metric.KG) {
        // triggering both field errors to show main error
        setPoundsError(" ");
        setStoneError(" ");

        changeHandler(field, { ...value, height: undefined });
        return;
      }
      changeHandler(field, { ...value, weight: newKg + "" });
    } else {
      changeHandler(field, { ...value, weight: undefined });
    }
    if (!validStone) {
      setStoneError("Must be an integer number.");
    } else {
      setStoneError("");
    }
    if (!validPounds) {
      setPoundsError("Must be a decimal number.");
    } else {
      setPoundsError("");
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderMetricInputs = () => {
    return (
      <>
        <MetricHeight
          field={field}
          onChange={metricHeightChanged}
          cm={value?.height ? Number(value.height) : 0}
          error={cmError}
        />
        <MetricWeight
          field={field}
          onChange={metricWeightChanged}
          kg={value?.weight ? Number(value.weight) : 0}
          error={kgError}
        />
      </>
    );
  };

  const renderImperialInputs = () => {
    return (
      <>
        <ImperialHeight
          field={field}
          onChange={imperialHeightChanged}
          inches={Number(
            convert(value?.height ? Number(value.height) : 0, "cm").to("inches")
          )}
          errors={{ feet: feetError, inches: inchesError }}
        />
        <ImperialWeight
          field={field}
          onChange={imperialWeightChanged}
          pounds={Number(
            convert(value?.weight ? Number(value.weight) : 0, "kg").to("pounds")
          )}
          errors={{ stone: stoneError, pounds: poundsError }}
        />
      </>
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <Stack spacing={3}>
      <Stack spacing={2} direction="row">
        <Button
          disabled={field.disabled}
          size="large"
          variant={metric ? "contained" : "outlined"}
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            setMetric(true);
            setCmError("");
            setKgError("");
          }}
        >
          Metric
        </Button>
        <Button
          disabled={field.disabled}
          size="large"
          variant={metric === false ? "contained" : "outlined"}
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            setMetric(false);
            setFeetError("");
            setInchesError("");
            setStoneError("");
            setPoundsError("");
          }}
        >
          Imperial
        </Button>
      </Stack>
      {metric === true && renderMetricInputs()}
      {metric === false && renderImperialInputs()}
    </Stack>
  );
};
