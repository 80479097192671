import { Stack, Button } from "@mui/material";
import { useState } from "react";
import { APIEditor } from "./APIEditor";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { EditorDialog } from "./EditorDialog";

export const APIsEditor = (props: { originalAPIs: any; onSave: (APIs: any) => void }) => {
  const { originalAPIs, onSave } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editAPIs, setEditAPIs] = useState(originalAPIs);

  const applyChanges = () => {
    onSave(editAPIs);
    closeDialog();
  };

  const discardChanges = () => {
    closeDialog();
    setEditAPIs(originalAPIs);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const saveAPI = (api: any, name: string) => {
    const newEditAPIs = { ...editAPIs, [name.toLowerCase()]: api };
    setEditAPIs(newEditAPIs);
  };

  const renderAPI = (API: any, name: string) => {
    return <APIEditor name={name} originalAPI={API} onSave={saveAPI} />;
  };

  const renderInputs = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
        {renderAPI(originalAPIs.new, "NEW")}
        {renderAPI(originalAPIs.patch, "PATCH")}
        {renderAPI(originalAPIs.bound, "BOUND")}
        {renderAPI(originalAPIs.mta, "MTA")}
      </Stack>
    );
  };

  return (
    <div>
      <EditorDialog isOpen={dialogOpen} onCancel={discardChanges} onApply={applyChanges}>
        {renderInputs()}
      </EditorDialog>
      <Button variant="contained" size="small" onClick={openDialog} startIcon={<DataObjectIcon />}>
        APIs
      </Button>
    </div>
  );
};
