import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { TJourneyConfig } from "../types";
import { JourneyContext } from "../JourneyContext";
import { formatToMoney } from "./FormFields/NumberField";
import moment from "moment";
import { activeJourneyContext } from "../activeJourneyContext";

const HeaderGbpSymbol = (props: { amount: number | string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} id="headerGbpSymbol">
      <Box component="span" id="symbol" sx={{ color: "primary.contrastText" }}>
        £
      </Box>
      <Typography
        variant="h1"
        id="amount"
        sx={{ color: "primary.contrastText" }}
      >
        {props.amount}
      </Typography>
    </Stack>
  );
};

export const QuoteHeader = (props: {
  config: TJourneyConfig;
  quote: any;
  MTA: any;
  paymentFrequency: "MONTHLY" | "YEARLY";
  IPT: boolean;
}) => {
  const { paymentFrequency, quote, MTA, IPT, config } = props;

  const activeJourney = useContext(activeJourneyContext);

  const isScratchAndDent1Edge =
    activeJourney?.product.id === "pro-e81ce45f-6909-485f-81d7-f7935065446d";

  const yearly = paymentFrequency.toUpperCase() !== "MONTHLY";
  const currentDate = moment().format("DD/MM/YYYY");
  // This is terrible, but it will have to do for now
  const yearlyText =
    config.product.id === "pro-af131b02-5d32-45be-ab7f-721199896c69" ||
    config.product.id === "pro-87b397be-396b-4f36-9590-873548e94f5e"
      ? "yearly"
      : "one-off";

  const monthlyPremium = quote?.monthlyPremium ?? "??";
  const premium = quote?.premium
    ? formatToMoney(Number(quote?.premium).toFixed(2))
    : "??";

  const pcQuote = quote?.pcQuote ?? MTA?.pcQuote;

  if (MTA === undefined) {
    return (
      <Box sx={{ color: "primary.contrastText" }}>
        <Typography
          variant="h1"
          sx={{ color: "primary.contrastText", textAlign: "center" }}
        >
          Your quote
        </Typography>
        <Stack spacing={1}>
          {config.payment?.provider === "stripe" ? (
            <PCQuoteHeader
              paymentFrequency={paymentFrequency}
              yearlyText={yearlyText}
              pcQuote={pcQuote}
              premium={premium}
              quote={quote}
              currentDate={currentDate}
              isScratchAndDent1Edge={isScratchAndDent1Edge}
            />
          ) : (
            <Stack spacing={2}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h3"
                  id="paymentText"
                  sx={{ color: "primary.contrastText" }}
                >
                  {yearly
                    ? `Your ${yearlyText} payment is`
                    : "Your monthly payment is"}
                </Typography>
                <HeaderGbpSymbol
                  amount={
                    paymentFrequency === "MONTHLY" ? monthlyPremium : premium
                  }
                />
              </Stack>
            </Stack>
          )}
          {IPT && (
            <p style={{ fontSize: 14, marginTop: 16 }}>
              All premiums are inclusive of Insurance Premium Tax (IPT) which is
              12%
            </p>
          )}
        </Stack>
      </Box>
    );
  }

  const newOneOffPayment =
    Number(MTA.mtaAdjustment) + Number(MTA.mtaFee) <= 0
      ? 0
      : Number(MTA.mtaAdjustment) + Number(MTA.mtaFee);

  return MTA ? (
    <Box sx={{ color: "primary.contrastText" }}>
      <Stack spacing={1}>
        {paymentFrequency === "MONTHLY" && MTA.paymentProvider === "stripe" ? (
          <MonthlyMTAHeader
            paymentFrequency={paymentFrequency}
            newOneOffPayment={newOneOffPayment}
            quote={quote}
            MTA={MTA}
          />
        ) : (
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="paymentText">New one-off payment is</p>
              <HeaderGbpSymbol amount={newOneOffPayment.toFixed(2)} />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="paymentText">
                {yearly
                  ? "Previous yearly premium"
                  : "Previous monthly premium"}
              </p>
              <p className="paymentText">
                <b>
                  £{" "}
                  {paymentFrequency === "MONTHLY"
                    ? (Number(quote.premium) / 12).toFixed(2)
                    : Number(quote.premium).toFixed(2)}
                </b>
              </p>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="paymentText">
                {yearly ? "New yearly premium" : "New monthly premium"}
              </p>
              <p className="paymentText">
                <b>
                  £{" "}
                  {paymentFrequency === "MONTHLY"
                    ? (Number(MTA.premium) / 12).toFixed(2)
                    : Number(MTA.premium).toFixed(2)}
                </b>
              </p>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="paymentText">Policy adjustment fee</p>
              <p className="paymentText">
                <b>£ {Number(MTA.mtaFee).toFixed(2)}</b>
              </p>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="paymentText">Premium adjustment balance</p>
              <p className="paymentText">
                <b>£ {Number(MTA.mtaAdjustment).toFixed(2)}</b>
              </p>
            </Stack>
          </Stack>
        )}

        <small style={{ fontSize: 11 }}>
          All premiums are inclusive of Insurance Premium Tax (IPT) which is 12%
        </small>
      </Stack>
    </Box>
  ) : (
    <h4>No quote or MTA</h4>
  );
};

const PCQuoteHeader = ({
  paymentFrequency,
  yearlyText,
  pcQuote,
  premium,
  quote,
  currentDate,
  isScratchAndDent1Edge,
}: {
  paymentFrequency: "MONTHLY" | "YEARLY";
  yearlyText: "yearly" | "one-off";
  pcQuote: any;
  premium: string | undefined;
  quote: any;
  currentDate: string;
  isScratchAndDent1Edge: boolean;
}) => {
  return (
    <Box sx={{ color: "primary.contrastText" }}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {paymentFrequency.toUpperCase() !== "MONTHLY" && (
            <>
              <Typography
                variant="h3"
                id="paymentText"
                sx={{ color: "primary.contrastText" }}
              >
                Your {yearlyText} payment is
              </Typography>
            </>
          )}
          {paymentFrequency === "MONTHLY" ? (
            <Stack direction="column">
              <p style={{ fontSize: 18 }}>
                Deposit of{" "}
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  £{quote?.deposit}
                </span>{" "}
                followed by {pcQuote?.numberOfCollections} monthly payments.
              </p>
              <p style={{ fontSize: 18 }}>
                1 instalment of{" "}
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  £{pcQuote?.firstCollectionAmount}
                </span>{" "}
                (including facility fee of £{pcQuote?.facilityFeeAmount}).
              </p>
              <p style={{ fontSize: 18 }}>
                {pcQuote?.numberOfCollections - 1} instalments of{" "}
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  £{pcQuote?.recurringPayment}
                </span>
                .
              </p>
              <p style={{ fontSize: 18, marginTop: "10px" }}>
                Total amount payable ={" "}
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  £{pcQuote?.totalAmountPayable}
                </span>{" "}
                (Including deposit of £{quote?.deposit},{" "}
                {pcQuote?.annualPercentageRate}% interest and facility fee of £
                {pcQuote?.facilityFeeAmount}).
              </p>
            </Stack>
          ) : (
            <HeaderGbpSymbol amount={premium ?? ""} />
          )}
        </Stack>
        {isScratchAndDent1Edge && <p>Price valid as of {currentDate}</p>}
      </Stack>
    </Box>
  );
};

const MonthlyMTAHeader = ({
  paymentFrequency,
  newOneOffPayment,
  quote,
  MTA,
}: {
  paymentFrequency: "MONTHLY" | "YEARLY";
  newOneOffPayment: number;
  quote: any;
  MTA: any;
}) => {
  return (
    <Stack spacing={1}>
      {MTA.stripeCollects && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <p className="paymentText">New one-off payment is</p>
          <HeaderGbpSymbol amount={newOneOffPayment.toFixed(2)} />
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <p className="paymentText">Previous monthly premium</p>
        <p className="paymentText">
          <b>£ {MTA?.oldMonthlyPremium}</b>
        </p>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <p className="paymentText">New monthly premium</p>
        <p className="paymentText">
          <b>£ {MTA?.monthlyPremium}</b>
        </p>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <p className="paymentText">Policy adjustment fee</p>
        <p className="paymentText">
          <b>£ {Number(MTA.mtaFee).toFixed(2)}</b>
        </p>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <p className="paymentText">Premium adjustment balance</p>
        <p className="paymentText">
          <b>£ {Number(MTA.mtaAdjustment).toFixed(2)}</b>
        </p>
      </Stack>
    </Stack>
  );
};
