import { Alert, AlertTitle, Container, Dialog, DialogContent, Stack } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getTitleFromKey, toTitleCase } from "../utils";

// =================================================================================================
// Main component
// =================================================================================================

export const MTASuccessPage = (props: { MTA: any }) => {
  const { MTA } = props;

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderLink = (doc: { url: string; key: string }) => {
    if (doc.url && doc.key) {
      return (
        <Stack direction="row" alignItems="top" spacing={1} key={doc.key}>
          <OpenInNewIcon sx={{ color: "#009BF5" }} />
          <a
            href={doc.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#009BF5", textDecoration: "none", textTransform: "capitalize" }}
          >
            {getTitleFromKey(doc.key)}
          </a>
        </Stack>
      );
    }
    return undefined;
  };

  // -----------------------------------------------------------------------------------------------
  // Main rendering
  // -----------------------------------------------------------------------------------------------

  if (MTA === null) {
    return null;
  }

  return (
    <Dialog
      open={true}
      scroll="paper"
      fullWidth
      PaperProps={{ sx: { width: "100%", margin: "0px" } }}
    >
      <DialogContent className="dialogContent">
        <Stack sx={{ width: "100%", height: "100%" }} justifyContent="center" alignItems="center">
          <Container
            maxWidth="sm"
            sx={{ backgroundColor: "white", borderRadius: "20px", padding: 4 }}
          >
            <Stack justifyContent="center" alignItems="center" spacing={3}>
              {MTA.proposerName && <h2>Dear {toTitleCase(MTA.proposerName)}</h2>}
              <Alert severity="success" sx={{ border: "1px solid rgba(0,0,0,0.5)", width: "100%" }}>
                {<AlertTitle>MTA Successful</AlertTitle>}
                <Stack spacing={2}>The policy has been updated!</Stack>
              </Alert>
              {MTA.policyNumber && (
                <p>
                  Your policy number is <b>{MTA.policyNumber}</b>
                </p>
              )}
              {MTA.proposerEmail && (
                <p>
                  Email will be sent to <b>{MTA.proposerEmail}</b>
                </p>
              )}
              {MTA.documents &&
                MTA.documents.length > 0 &&
                MTA.documents.map((doc: { url: string; key: string }) => renderLink(doc))}
            </Stack>
          </Container>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
