import { Card, Stack, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const EditorCard = (props: { children: any; onActivate: () => void }) => {
  const { children, onActivate } = props;
  return (
    <Card variant="outlined" sx={{ padding: 1 }}>
      <Stack direction="row" alignItems="top" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>{children}</div>
        <div>
          <IconButton onClick={onActivate}>
            <EditIcon color="primary" />
          </IconButton>
        </div>
      </Stack>
    </Card>
  );
};
